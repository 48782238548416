import { Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';

const HeaderComponent = ({ headers = [] }) => {
  const components = headers.map(({ heading }) => (
    <Box className="heading-container" key={heading}>
      <Typography variant="subtitle2" className="heading-container-heading">
        {heading}
      </Typography>
    </Box>
  ));
  return <>{components}</>;
};

HeaderComponent.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    })
  ),
};

export default HeaderComponent;
