import { Box, styled, Typography } from '@mui/material';
import WarningIcon from '../../assets/img/warningIconV2.svg';
import PropTypes from 'prop-types';

const WarningMessageTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.188rem',
  color: theme.palette.text.lightYellowishGray,
  marginLeft: '0.563rem',
  alignSelf: 'center',
}));

const WarningMessage = ({ children, dataTestid }) => {
  return (
    <Box sx={{ marginLeft: '2.25rem', display: 'flex', flexDirection: 'row' }}>
      <WarningIcon height={24} width={24} />
      <WarningMessageTypography data-testid={dataTestid}>
        {children}
      </WarningMessageTypography>
    </Box>
  );
};
WarningMessage.propTypes = {
  children: PropTypes.node,
  dataTestid: PropTypes.string,
};

export default WarningMessage;
