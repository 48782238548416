import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import LoadingIndicator from '../../../components/LoadingIndicator';
import {
  MESSAGE_STRINGS,
  QUERY_CONSTANTS,
  TARGETS_HEADER_CONSTANTS,
} from '../../../constants/OeeConstants';
import { useToastContext } from '../../../context/toastContext';
import { getTargetsHierarchy } from '../../../utils/apiHelpers';
import CustomBackdrop from '../../../styledComponents/CustomBackdrop';
import PanelTable from '../../../components/HierarchyTable';
import { Box } from '@mui/material';
import {
  onApiWarningToastHandler,
  onGetHierarchySuccessHandler,
} from '../../../utils/helpers';

const TargetsTable = ({
  isValidationSuccess,
  entityId,
  isFileNewlyUploaded,
}) => {
  const [tableHierarchyData, setTableHierarchyData] = useState(null);
  const { toastDispatch } = useToastContext();

  const {
    isFetching: isTargetsValueHierarchyFetching,
    isLoading: isTargetsValueHierarchyLoading,
    refetch: refetchTargetsHierarchy,
  } = useQuery(
    [QUERY_CONSTANTS.GET_TARGETS_VALUE_HIERARCHY, entityId],
    async () => {
      return getTargetsHierarchy(entityId);
    },
    {
      onSuccess: (response) => {
        onGetHierarchySuccessHandler(
          setTableHierarchyData,
          response,
          isFileNewlyUploaded,
          toastDispatch
        );
      },
      onError: () => onApiWarningToastHandler(toastDispatch),
      refetchOnWindowFocus: false,
      enabled: Boolean(entityId),
      retry: false,
    }
  );

  useEffect(() => {
    if (isValidationSuccess) {
      refetchTargetsHierarchy();
    }
  }, [isValidationSuccess]);

  const backdropLoader =
    isTargetsValueHierarchyFetching || isTargetsValueHierarchyLoading;

  return (
    <>
      {tableHierarchyData && (
        <PanelTable
          isLoading={false}
          data={tableHierarchyData.data}
          headerKeys={[
            TARGETS_HEADER_CONSTANTS.OEE_TARGETS,
            TARGETS_HEADER_CONSTANTS.AVAILABILITY_TARGETS,
            TARGETS_HEADER_CONSTANTS.PERFORMANCE_TARGETS,
            TARGETS_HEADER_CONSTANTS.QUALITY_TARGETS,
          ]}
          nestingLevel={5}
          treePanelHeader={[
            {
              heading: MESSAGE_STRINGS['OEE.tree.header.equipmentHierarchy'],
            },
            {
              heading: MESSAGE_STRINGS['OEE.tree.header.OEE'],
            },
            {
              heading: MESSAGE_STRINGS['OEE.tree.header.Availability'],
            },
            {
              heading: MESSAGE_STRINGS['OEE.tree.header.Performance'],
            },
            {
              heading: MESSAGE_STRINGS['OEE.tree.header.Quality'],
            },
          ]}
        />
      )}
      <Box display="flex" maxHeight="100%" flex={1} flexDirection="column">
        <CustomBackdrop open={backdropLoader}>
          <LoadingIndicator />
        </CustomBackdrop>
      </Box>
    </>
  );
};

TargetsTable.propTypes = {
  entityId: PropTypes.string,
  isValidationSuccess: PropTypes.bool,
  isFileNewlyUploaded: PropTypes.bool,
};

export default TargetsTable;
