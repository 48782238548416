import { Typography, styled } from '@mui/material';
import React from 'react';
import DownArrow from '../../../assets/img/downArrow.svg';
import PropTypes from 'prop-types';
import { usePanelTableContext } from '..';
import {
  ENTITY_TYPES,
  IRR_HEADER_CONSTANTS,
} from '../../../constants/OeeConstants';

const CustomHeading = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'isHeadingDisabled' &&
    prop !== 'entityType' &&
    prop !== 'hasChildren',
})(({ theme, isHeadingDisabled, entityType, hasChildren }) => ({
  fontWeight:
    entityType === ENTITY_TYPES.CELL || !hasChildren ? '400 ' : '700 ',
  alignItems: 'center ',
  marginLeft: '0.625em ',
  lineHeight: '1.25em ',
  color: isHeadingDisabled ? theme.palette.text.darkGrey : undefined,
  textTransform: 'none',
}));

const CustomDownArrow = styled(DownArrow)(() => ({ alignSelf: 'center' }));

const LabelContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'depth',
})(({ depth }) => ({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '40% 60%',
  '& .label-heading': {
    display: 'flex',
    flex: 1,
    minWidth: 0,
    marginLeft: `${depth}rem`,
    '& .oee-MuiTypography-body1': {
      lineHeight: '1.188rem',
    },
    '& .oee-MuiTypography-subtitle2': {
      lineHeight: '1.188rem',
    },
  },
  '& .other-container': {
    display: 'grid',
    gridTemplateColumns: '20.75% 28.75% 28.75% 21.75%',
    '& .MuiTypography-body1': {
      lineHeight: '1.188rem',
    },
    '& .MuiTypography-subtitle2': {
      lineHeight: '1.188rem',
    },
  },
  '& .other-container .row-cell': {
    '& .MuiTypography-body1': {
      lineHeight: '1.188rem',
    },
    '& .MuiTypography-subtitle2': {
      lineHeight: '1.188rem',
    },
  },
}));

const HierarchyTableLabel = ({
  entityName,
  targetValue = {},
  isHeadingDisabled,
  entityNumber,
  entityType,
  entityChildren = [],
  depth = 0,
  nodes,
}) => {
  const hasChildren = entityChildren != null && entityChildren.length > 0;
  const { headerKeys = [] } = usePanelTableContext();
  const noIcon = [ENTITY_TYPES.PLANT, ENTITY_TYPES.AREA, ENTITY_TYPES.CELL];
  const getTargetValue = (key) => {
    if (targetValue[key] || targetValue[key] === 0) {
      if (key === IRR_HEADER_CONSTANTS.IDEAL_RUN_RATE) {
        return targetValue[key];
      }
      if (targetValue[key] === 'N/A') {
        return targetValue[key];
      }
      return targetValue[key] * 100;
    }
    return '-';
  };
  return (
    <LabelContainer depth={depth}>
      <div className="label-heading">
        {!noIcon.includes(nodes.entityType) && (
          <CustomDownArrow
            className={`${
              (noIcon.includes(nodes.entityType) || !hasChildren) &&
              'oee-noIcon'
            }`}
          />
        )}
        <CustomHeading
          variant="subtitle2"
          noWrap
          entityType={entityType}
          isHeadingDisabled={isHeadingDisabled}
          hasChildren={hasChildren}
        >
          {`${entityType}: `}
          {entityType === 'Plant'
            ? entityName
            : `${entityNumber}-${entityName}`}
        </CustomHeading>
      </div>
      <div className="other-container">
        {headerKeys.map((key) => (
          <div key={key} className="row-cell">
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: entityType === 'Cell' || !hasChildren ? 400 : 700,
                textTransform: 'none',
              }}
            >
              {getTargetValue(key)}
            </Typography>
          </div>
        ))}
      </div>
    </LabelContainer>
  );
};

HierarchyTableLabel.propTypes = {
  entityName: PropTypes.string,
  entityNumber: PropTypes.string,
  targetValue: PropTypes.shape({
    value: PropTypes.number,
  }),
  treeWidth: PropTypes.number,
  depth: PropTypes.number,
  nodes: PropTypes.shape({
    entityId: PropTypes.string,
    entityType: PropTypes.string,
    isDisabled: PropTypes.bool,
    entityName: PropTypes.string,
    type: PropTypes.string,
    entityChildren: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isHeadingDisabled: PropTypes.bool,
  entityType: PropTypes.string,
  entityChildren: PropTypes.arrayOf(PropTypes.shape({})),
};

export default HierarchyTableLabel;
