import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import SecondaryExportHeader from '../../../components/SecondaryExportHeader';
import { QUERY_CONSTANTS, TESTIDS } from '../../../constants/OeeConstants';
import TargetsTable from '../TargetsTable';
import SecondaryUploadPreview from '../../../components/SecondaryUploaderPreview';
import IdealRunRateTable from '../../IdealRunRate/IdealRunRateTable';
const ReuploaderAndHierarchyTable = ({
  fetchDownloadUrl,
  showTable,
  isValidateError,
  errorList,
  setShowTable,
  isUploadError,
  setIsUploadError,
  updateAccordionStatus,
  getPresignedUploadUrl,
  entityId,
  validate,
  resetValidate,
  removeExistingFileData,
  setFileWithMeta,
  isValidationSuccess,
  utility,
  isFileNewlyUploaded,
}) => {
  const dropZoneRef = useRef(null);
  const [removeUploadButton, setRemoveUploadButton] = useState(false);
  const openUploadCsv = () => {
    if (dropZoneRef.current) {
      dropZoneRef.current?.dropzone?.current?.firstChild?.lastChild?.click();
    }
  };
  const onSecondaryUpload = () => {
    openUploadCsv();
  };
  useEffect(() => {
    if (showTable) {
      setRemoveUploadButton(false);
    }
  }, [showTable]);
  return (
    <Box data-testid={TESTIDS.HIERARCHY_TABLE_COMPONENT}>
      <SecondaryExportHeader
        fetchDownloadUrl={fetchDownloadUrl}
        errorList={errorList}
        onSecondaryUpload={onSecondaryUpload}
        isUploadError={isUploadError}
        removeUploadButton={removeUploadButton}
        removeExistingFileData={removeExistingFileData}
      />
      {showTable &&
        (utility === QUERY_CONSTANTS.OEE_UTILITY ? (
          <TargetsTable
            isValidationSuccess={isValidationSuccess}
            isFileNewlyUploaded={isFileNewlyUploaded}
            entityId={entityId}
            data-testid={TESTIDS.TARGETS_TABLE}
          />
        ) : (
          <IdealRunRateTable
            isValidationSuccess={isValidationSuccess}
            isFileNewlyUploaded={isFileNewlyUploaded}
            entityId={entityId}
          />
        ))}
      <SecondaryUploadPreview
        isValidateError={isValidateError}
        isUploadError={isUploadError}
        ref={dropZoneRef}
        entityId={entityId}
        updateAccordionStatus={updateAccordionStatus}
        getPresignedUploadUrl={getPresignedUploadUrl}
        validate={validate}
        utility={utility}
        showTable={showTable}
        setShowTable={setShowTable}
        setRemoveUploadButton={setRemoveUploadButton}
        setIsUploadError={setIsUploadError}
        resetValidate={resetValidate}
        setFileWithMeta={setFileWithMeta}
      />
    </Box>
  );
};

ReuploaderAndHierarchyTable.propTypes = {
  fetchDownloadUrl: PropTypes.func,
  showTable: PropTypes.bool,
  setShowTable: PropTypes.func,
  isValidateError: PropTypes.bool,
  errorList: PropTypes.array,
  isUploadError: PropTypes.bool,
  updateAccordionStatus: PropTypes.func,
  getPresignedUploadUrl: PropTypes.func,
  entityId: PropTypes.string,
  validate: PropTypes.func,
  setIsUploadError: PropTypes.func,
  resetValidate: PropTypes.func,
  isValidationSuccess: PropTypes.bool,
  removeExistingFileData: PropTypes.func,
  setFileWithMeta: PropTypes.func,
  fileWithMeta: PropTypes.object,
  isFileNewlyUploaded: PropTypes.bool,
  utility: PropTypes.string,
};

export default ReuploaderAndHierarchyTable;
