import { Box, ButtonBase, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import WhiteDownloadIcon from '../../assets/img/whiteDownloadIcon.svg';
import { TESTIDS } from '../../constants/OeeConstants';
import HeaderBox from '../../styledComponents/HeaderBox';
import ErrorList from '../ErrorList';

function ExportHeader({
  fetchDownloadUrl,
  displayName,
  isValidateError,
  errorList,
}) {
  return (
    <HeaderBox>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          height: '3.5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: '1.094rem',
            width: '8.125rem',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <ButtonBase
            onClick={fetchDownloadUrl}
            disableRipple
            data-testid={TESTIDS.EXPORT_BUTTON}
          >
            <WhiteDownloadIcon
              width="1.8462rem"
              height="1.8462rem"
              alt="download-icon"
              data-testid="download-icon"
            />
            <Typography
              sx={{ width: '5.625rem', marginLeft: '0.635rem !important' }}
            >
              {displayName}
            </Typography>
          </ButtonBase>
        </Box>
      </Box>
      {isValidateError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ErrorList errorList={errorList} />
        </Box>
      )}
    </HeaderBox>
  );
}

export default ExportHeader;

ExportHeader.propTypes = {
  fetchDownloadUrl: PropTypes.func,
  displayName: PropTypes.string,
  isValidateError: PropTypes.bool,
  errorList: PropTypes.func,
};
