import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../components/Accordion/index';
import DownArrow from '../../assets/img/downArrow.svg';
import { Box, styled, ButtonBase } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import { useToastContext } from '../../context/toastContext';
import {
  ACCORDION_STATUS,
  OEE_ACCORDIONS,
  FILE_CONTENT_TYPES,
  MESSAGE_STRINGS,
  QUERY_CONSTANTS,
  TESTIDS,
} from '../../constants/OeeConstants';
import AccordionTitle from '../../styledComponents/AccordianTitle';
import AccordionStatus from '../../components/AccordionStatus';
import InitialUploder from '../../components/InitialUploader';
import {
  getIdealPresignedExportUrl,
  getIdealPresignedUploadUrl,
  getTargetsAndIdealRunRateStatus,
  idealValidateUpload,
} from '../../utils/apiHelpers';
import {
  convertCase,
  onApiErrorToastHandler,
  simulateUrlClick,
} from '../../utils/helpers';
import CustomBackdrop from '../../styledComponents/CustomBackdrop';
import LoadingIndicator from '../../components/LoadingIndicator';
import ReuploaderAndHierarchyTable from '../Targets/ReuploaderAndHierarchyTable';
import WarningMessage from '../../components/WarningMessage';

const WarningMessageBtn = styled(ButtonBase)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 700,
  textDecoration: 'underline',
  color: theme.palette.text.lightYellowishGray,
  backgroundColor: 'inherit',
  border: 'none',
  cursor: 'pointer',
}));

const IdealRunRate = ({
  onExpandUpdate = () => null,
  isExpanded = false,
  fetchConfigStatuses,
  setOpenWarningModal,
}) => {
  const { rxjsState } = useRxjsState();
  const { toastDispatch } = useToastContext();
  const [entityId, setEntityId] = useState(rxjsState.plantId);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isFileNewlyUploaded, setIsFileNewlyUploaded] = useState(false);
  const [errorList, setErrorlist] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [isUploadError, setIsUploadError] = useState(false);
  const [accordionStatus, setAccordionStatus] = useState(
    ACCORDION_STATUS.NOT_STARTED
  );
  const [fileWithMeta, setFileWithMeta] = useState(null);
  const [isIRRInSyncWithAIP, setIsIRRInSyncWithAIP] = useState(true);
  const [isUoMUpdated, setIsUoMUpdated] = useState(false);

  useEffect(() => {
    if (rxjsState.plantId) {
      setEntityId(rxjsState.plantId);
    }
  }, [rxjsState.plantId]);

  useEffect(() => {
    if (!isValidateIdealLoading && isValidationSuccess) {
      removeExistingFileData(fileWithMeta);
    }
  });

  const removeExistingFileData = (fileWithMetaToBeRemoved) => {
    if (fileWithMetaToBeRemoved) {
      fileWithMetaToBeRemoved.remove();
    }
  };

  const handleWarningBtnClick = (e) => {
    setOpenWarningModal((prev) => !prev);
    e.stopPropagation();
  };

  const { isLoading: isFetchStatusesLoading, refetch: fetchStatuses } =
    useQuery(
      [QUERY_CONSTANTS.OEE_TARGETS_AND_IDEAL_RUN_RATE_STATUS, entityId],
      async () => {
        return getTargetsAndIdealRunRateStatus(
          entityId,
          QUERY_CONSTANTS.OEE_UTILITY
        );
      },
      {
        onSuccess: (response) => {
          const status = convertCase(response.OEEIdealRunRateStatus.status);
          setAccordionStatus(status);
          setIsFileUploaded(response.OEEIdealRunRateStatus.isIDRRUploaded);
          setShowTable(response.OEEIdealRunRateStatus.isIDRRUploaded);
          setIsIRRInSyncWithAIP(
            response.OEEIdealRunRateStatus.isIdealRunRateInSyncWithAIP
          );
          setIsUoMUpdated(response.OEEIdealRunRateStatus.isUoMUpdated);
        },
        onError: () => onApiErrorToastHandler(toastDispatch),
        refetchOnWindowFocus: false,
        retry: false,
        enabled: Boolean(entityId),
      }
    );

  const { isLoading: isDownloadUrlLoading, refetch: fetchDownloadUrl } =
    useQuery(
      [QUERY_CONSTANTS.EXPORT_IDEAL_XLSX],
      async () => {
        return getIdealPresignedExportUrl(
          entityId,
          QUERY_CONSTANTS.IDEAL_RR_UTILITY
        );
      },
      {
        onSuccess: (response) => {
          const status = convertCase(response.idealRunRateStatus.status);
          setAccordionStatus(status);
          simulateUrlClick(response.data, FILE_CONTENT_TYPES.XLSX);
          return response;
        },
        onError: () => onApiErrorToastHandler(toastDispatch),
        refetchOnWindowFocus: false,
        retry: false,
        enabled: false,
      }
    );

  const {
    isLoading: isValidateIdealLoading,
    isError: isValidateIdealError,
    mutate: validateIdealRunRate,
    reset: resetValidateIdealRunRate,
    isSuccess: isValidationSuccess,
  } = useMutation(
    [QUERY_CONSTANTS.VALIDATE_XLSX],
    async (fileName) => {
      const params = {
        entityId: entityId,
        fileName,
      };
      return idealValidateUpload(params);
    },
    {
      onSuccess: (validateResponse) => {
        const accordionProgressStatus = convertCase(
          validateResponse.idealRunRateStatus.status
        );

        setAccordionStatus(accordionProgressStatus);
        fetchConfigStatuses();
        setIsFileNewlyUploaded(true);
        setIsUploadError(false);
        setShowTable(validateResponse.idealRunRateStatus.isIDRRUploaded);
        fetchStatuses();
        setIsIRRInSyncWithAIP(
          validateResponse.idealRunRateStatus.isIdealRunRateInSyncWithAIP
        );
        setIsUoMUpdated(validateResponse.idealRunRateStatus.isUoMUpdated);
      },
      onError: (error) => {
        if (
          error?.response?.data?.message
        ) {
          const listOfErrors = error.response.data.message.split('\n');
          setAccordionStatus(convertCase(ACCORDION_STATUS.IN_PROGRESS));
          setErrorlist(listOfErrors);
          setIsUploadError(true);
        }
      },
      enabled: Boolean(entityId),
      retry: false,
    }
  );
  const backdropLoader =
    isDownloadUrlLoading || isValidateIdealLoading || isFetchStatusesLoading;
  return (
    <Accordion
      id={'ideal-run-rate'}
      expanded={isExpanded}
      onChange={() => {
        onExpandUpdate(OEE_ACCORDIONS.IDEAL_RUN_RATE);
      }}
    >
      <Accordion.AccordionSummary
        aria-controls="ideal-content"
        id="ideal-content"
        expandIcon={<DownArrow height={10} width={16} />}
        data-testid={TESTIDS.IRR_ACCORDION}
      >
        <Box>
          <AccordionTitle>
            {MESSAGE_STRINGS.IDEAL_RUN_RATE_ACCORDION_HEADER}
          </AccordionTitle>
        </Box>
        {!isIRRInSyncWithAIP && !isUoMUpdated && (
          <WarningMessage dataTestid={TESTIDS.IRR_AIP_WARNING}>
            {MESSAGE_STRINGS['AIP.warning.IRR.message']}
          </WarningMessage>
        )}
        {isUoMUpdated && isIRRInSyncWithAIP && (
          <WarningMessage dataTestid={TESTIDS.IRR_UOM_WARNING}>
            {MESSAGE_STRINGS['UoM.warning.IRR.message']}
          </WarningMessage>
        )}
        {!isIRRInSyncWithAIP && isUoMUpdated && (
          <WarningMessage dataTestid={TESTIDS.IRR_MULTIPLE_WARNING}>
            <WarningMessageBtn
              disableRipple
              onClick={(e) => handleWarningBtnClick(e)}
              data-testid={TESTIDS.WARNING_BUTTON}
            >
              {MESSAGE_STRINGS['AIP.warning.button']}
            </WarningMessageBtn>
            {MESSAGE_STRINGS['AIP.warning.IRR.prompt']}
          </WarningMessage>
        )}
        <AccordionStatus
          type={OEE_ACCORDIONS.IDEAL_RUN_RATE}
          status={accordionStatus}
        />
      </Accordion.AccordionSummary>
      <Accordion.AccordionDetails>
        <Box sx={{ width: '100%' }} data-testid={TESTIDS.IRR_CONTENT}>
          {!isFileUploaded ? (
            <InitialUploder
              fetchDownloadUrl={fetchDownloadUrl}
              errorList={errorList}
              entityId={entityId}
              isValidateError={isValidateIdealError}
              validate={validateIdealRunRate}
              resetValidate={resetValidateIdealRunRate}
              updateAccordionStatus={setAccordionStatus}
              utility={QUERY_CONSTANTS.IDEAL_RR_UTILITY}
              getPresignedUploadUrl={getIdealPresignedUploadUrl}
            />
          ) : (
            <ReuploaderAndHierarchyTable
              showTable={showTable}
              setShowTable={setShowTable}
              fetchDownloadUrl={fetchDownloadUrl}
              isValidateError={isValidateIdealError}
              isUploadError={isUploadError}
              setIsUploadError={setIsUploadError}
              updateAccordionStatus={setAccordionStatus}
              getPresignedUploadUrl={getIdealPresignedUploadUrl}
              entityId={entityId}
              validate={validateIdealRunRate}
              errorList={errorList}
              resetValidate={resetValidateIdealRunRate}
              isValidationSuccess={isValidationSuccess}
              setFileWithMeta={setFileWithMeta}
              fileWithMeta={fileWithMeta}
              removeExistingFileData={removeExistingFileData}
              utility={QUERY_CONSTANTS.IDEAL_RR_UTILITY}
              isFileNewlyUploaded={isFileNewlyUploaded}
            />
          )}
        </Box>
        <Box
          display="flex"
          maxHeight="100%"
          flex={1}
          flexDirection="column"
          data-testid={TESTIDS.BACKDROP}
        >
          <CustomBackdrop open={backdropLoader}>
            <LoadingIndicator />
          </CustomBackdrop>
        </Box>
      </Accordion.AccordionDetails>
    </Accordion>
  );
};

IdealRunRate.propTypes = {
  onExpandUpdate: PropTypes.func,
  isExpanded: PropTypes.bool,
  fetchConfigStatuses: PropTypes.func,
  setOpenWarningModal: PropTypes.func,
  setWarningMessages: PropTypes.func,
};
export default IdealRunRate;
