import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Actuals from '../Actuals';
import Targets from '../Targets';
import IdealRunRate from '../IdealRunRate';
import {
  MESSAGE_STRINGS,
  OEE_ACCORDIONS,
  OEE_TITLE,
  QUERY_CONSTANTS,
  TESTIDS,
} from '../../constants/OeeConstants';
import DeviationThreshold from '../DeviationThresholds';
import { getFactoryConfigStatuses } from '../../utils/apiHelpers';
import { useQuery } from 'react-query';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import { updateRxjsState } from '../../utils/rxjsStateNext';
import CustomBackdrop from '../../styledComponents/CustomBackdrop';
import WarningModal from '../../components/WarningModal/WarningModal';

const SubConfigContainer = styled(Box)(() => ({
  marginBottom: '0.313em',
}));
const OeeMainContainer = styled(Box)(() => ({
  display: 'flex',
  maxHeight: '100%',
  flexDirection: 'column',
  height: '100%',
}));
const HeadingBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1rem',
  margin: '1rem 1.313rem',
}));
const SubConfigurationsWrapperBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.layoutBackgroundColor,
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '100%',
  margin: '0 0.875rem',
  border: `0.313em solid ${theme.palette.background.layoutBackgroundColor}`,
  borderRadius: '0.25rem 0.25rem 0px 0px',
  height: '100%',
}));
const MainTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '2.043rem',
  color: theme.customColors.white,
}));
const OEE = () => {
  const initialSelectedAccordion = [
    { type: OEE_ACCORDIONS.ACTUALS, expanded: true },
    { type: OEE_ACCORDIONS.TARGETS, expanded: false },
    { type: OEE_ACCORDIONS.IDEAL_RUN_RATE, expanded: false },
    { type: OEE_ACCORDIONS.DEVIATION_THRESHOLD, expanded: false },
  ];

  const { rxjsState } = useRxjsState();
  const [entityId, setEntityId] = useState(rxjsState.plantId);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const [warningMessages, setWarningMessages] = useState([
    MESSAGE_STRINGS['AIP.warning.IRR.message'],
    MESSAGE_STRINGS['UoM.warning.IRR.message'],
  ]);

  useEffect(() => {
    if (rxjsState.plantId) {
      setEntityId(rxjsState.plantId);
    }
  }, [rxjsState.plantId]);

  const [selectedAccordion, setSelectedAccordion] = useState(
    initialSelectedAccordion
  );
  const onExpandUpdate = (expandedAccordion) => {
    setSelectedAccordion((prevAccordion) =>
      prevAccordion.map((item) => ({
        ...item,
        expanded: item.type === expandedAccordion,
      }))
    );
  };
  const accordionIsExpanded = (accordion) => {
    return selectedAccordion.find((item) => item.type === accordion).expanded;
  };

  const { refetch: fetchConfigStatuses } = useQuery(
    [QUERY_CONSTANTS.GET_CONFIG_STATUSES, entityId],
    async () => {
      return getFactoryConfigStatuses(entityId);
    },
    {
      onSuccess: (response) => {
        const { oee } = response;
        updateRxjsState({
          configStatus: {
            ...rxjsState.configStatus,
            oee,
          },
        });
      },
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return (
    <OeeMainContainer data-testid={TESTIDS.OEE_MAIN}>
      <HeadingBox>
        <Box marginRight={1}>
          <MainTitleTypography variant="h3" id="factoryLabel">
            {OEE_TITLE}
          </MainTitleTypography>
        </Box>
      </HeadingBox>

      <SubConfigurationsWrapperBox>
        <SubConfigContainer>
          <Actuals
            onExpandUpdate={onExpandUpdate}
            isExpanded={accordionIsExpanded(OEE_ACCORDIONS.ACTUALS)}
            fetchConfigStatuses={fetchConfigStatuses}
          />
        </SubConfigContainer>
        <SubConfigContainer>
          <Targets
            onExpandUpdate={onExpandUpdate}
            isExpanded={accordionIsExpanded(OEE_ACCORDIONS.TARGETS)}
            fetchConfigStatuses={fetchConfigStatuses}
          />
        </SubConfigContainer>
        <SubConfigContainer>
          <IdealRunRate
            onExpandUpdate={onExpandUpdate}
            isExpanded={accordionIsExpanded(OEE_ACCORDIONS.IDEAL_RUN_RATE)}
            fetchConfigStatuses={fetchConfigStatuses}
            setOpenWarningModal={setOpenWarningModal}
            setWarningMessages={setWarningMessages}
          />
        </SubConfigContainer>
        <SubConfigContainer>
          <DeviationThreshold
            onExpandUpdate={onExpandUpdate}
            isExpanded={accordionIsExpanded(OEE_ACCORDIONS.DEVIATION_THRESHOLD)}
            fetchConfigStatuses={fetchConfigStatuses}
          />
        </SubConfigContainer>
        <CustomBackdrop open={openWarningModal}>
          <WarningModal
            warningMessages={warningMessages}
            setOpenWarningModal={setOpenWarningModal}
          />
        </CustomBackdrop>
      </SubConfigurationsWrapperBox>
    </OeeMainContainer>
  );
};

export default OEE;
