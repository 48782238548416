import { styled } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import {
  createContext,
  useContext,
  forwardRef,
  useRef,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import RenderData from '../../HierarchyTable/RenderData';

const TreeViewContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const TreeViewHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isIRR',
})(({ theme, isIRR = false }) => ({
  backgroundColor: theme.palette.background.darkishBlackBlue,
  minHeight: '3.5rem',
  display: 'grid',
  gridTemplateColumns: isIRR ? '40% 8.5rem' : '40% 12% 17% 17% 14%',
  alignItems: 'center',
  '& > :first-child': {
    marginLeft: '0.625em',
  },
  '& > :not(:first-child)': {
    width: '100%',
    maxWidth: '11em',
  },
  '& .heading-container': {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '11em',
    lineHeight: '1.25em',
    '& .heading-container-heading': {
      color: theme.palette.text.lightUnitGrey,
    },
  },
}));

const HoverBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hoverBoxPosition',
})(({ theme, hoverBoxPosition }) => ({
  width: '100%',
  height: '2.5em',
  backgroundColor: theme.palette.background.blackGrey,
  position: 'absolute',
  top: hoverBoxPosition.top,
  left: hoverBoxPosition.left,
}));

const StyledTreeView = styled(TreeView)(() => ({
  paddingRight: 3,
  height: '35rem',
  overflowY: 'auto',
  scrollBehavior: 'smooth',
  '& .MuiTreeItem-root': {
    marginBottom: '1rem',
  },
  position: 'relative',
  width: '100%',
}));

const MainTree = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  width: '100%',
}));

const TreePanelContext = createContext();

export const useTreePanelContext = () => useContext(TreePanelContext);

const TreePanel = forwardRef(
  (
    {
      data = {},
      selectedNodes = [],
      selected = [],
      handleSelect,
      expanded = [],
      handleToggle,
      header = null,
      LabelComponent = () => null,
      dynamicHeightFactor = 1.5,
      treeWidth,
      isIRR,
    },
    ref
  ) => {
    const mainTreeRef = useRef();

    const liDynamicHeight = mainTreeRef.current
      ? `${mainTreeRef.current.scrollHeight * dynamicHeightFactor}px`
      : '10rem';

    const [hoverBoxPosition, setHoverBoxPosition] = useState({
      left: -9999,
      top: 0,
    });

    function onMouseEnter(e) {
      if (!e.target.classList.contains('MuiTreeItem-content')) {
        return;
      }
      const { top } = e.target.getBoundingClientRect();
      const { y } = mainTreeRef.current.getBoundingClientRect();
      setHoverBoxPosition({ top: top - y, left: 0 });
    }

    function onMouseLeave() {
      setHoverBoxPosition({
        top: 0,
        left: -9999,
      });
    }

    const providerValue = useMemo(
      () => ({
        onMouseEnter,
        onMouseLeave,
      }),
      []
    );

    return (
      <TreeViewContainer>
        <TreeViewHeader isIRR={isIRR}>{header}</TreeViewHeader>
        <StyledTreeView
          ref={(r) => {
            // eslint-disable-next-line no-param-reassign
            ref.current = r;
          }}
          selected={selected}
          expanded={expanded}
          defaultSelected={selectedNodes}
          defaultExpanded={selectedNodes}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
        >
          <li
            style={{
              height: liDynamicHeight,
            }}
          />
          <HoverBox hoverBoxPosition={hoverBoxPosition} />
          <MainTree ref={mainTreeRef}>
            <TreePanelContext.Provider value={providerValue}>
              <RenderData
                key="mainTree"
                nodes={data}
                index={-1}
                selected={selected}
                LabelComponent={LabelComponent}
                treeWidth={treeWidth}
                depth={0}
              />
            </TreePanelContext.Provider>
          </MainTree>
        </StyledTreeView>
      </TreeViewContainer>
    );
  }
);

TreePanel.displayName = 'Tree Panel';
TreePanel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  treeWidth: PropTypes.number,
  data: PropTypes.object,
  selectedNodes: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.arrayOf(PropTypes.string),
  handleSelect: PropTypes.func,
  expanded: PropTypes.arrayOf(PropTypes.string),
  handleToggle: PropTypes.func,
  header: PropTypes.node,
  dynamicHeightFactor: PropTypes.number,
  LabelComponent: PropTypes.func,
  isIRR: PropTypes.bool,
};

export default TreePanel;
