import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import GeneralButton from '../GeneralButton';
import { MESSAGE_STRINGS, TESTIDS } from '../../constants/OeeConstants';
const ModalBackground = styled(Box)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.customColors.modalBackdrop,
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
const MainModalContainer = styled(Box)(({ theme }) => ({
  width: '37.5rem',
  height: '17.25rem',
  backgroundColor: theme.palette.background.blackGrey,
  borderRadius: '0.5rem',
}));
const ModalTitleBox = styled(Box)(({ theme }) => ({
  height: '4rem',
  padding: '1.125rem 0rem 1.188rem 1.5rem',
  borderBottom: `1px solid ${theme.palette.border.wrapperGrey}`,
}));
const ModalTitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '1.25rem',
  fontWeight: 700,
  lineHeight: '1.703rem',
  color: theme.customColors.white,
}));
const ModalContentBox = styled(Box)(() => ({
  height: '7.5rem',
  padding: '1.5rem 5.43rem 0rem 1.5rem',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '1rem',
}));
const WarningTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '1.192rem',
  color: theme.customColors.white,
}));
const ListTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  fontWeight: 700,
  lineHeight: '1.192rem',
  color: theme.customColors.white,
}));
const WarningModal = ({ warningMessages, setOpenWarningModal }) => {
  return (
    <ModalBackground data-testid={TESTIDS.WARNING_MODAL}>
      <MainModalContainer>
        <ModalTitleBox>
          <ModalTitleTypography>
            {MESSAGE_STRINGS['WarningModal.title']}
          </ModalTitleTypography>
        </ModalTitleBox>
        <ModalContentBox>
          {warningMessages.map((warning, idx) => {
            return (
              <Box
                key={idx}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  height: '2.5rem',
                }}
              >
                <ListTypography sx={{ height: '1.188rem' }}>{`${
                  idx + 1
                }.`}</ListTypography>
                <WarningTypography
                  sx={{ marginLeft: '0.75rem', height: '2.5rem' }}
                  data-testid={TESTIDS.WARNING_MESSAGE}
                >
                  {warning}
                </WarningTypography>
              </Box>
            );
          })}
        </ModalContentBox>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            marginTop: '2rem',
            paddingRight: '1.5rem',
          }}
        >
          <GeneralButton
            onClick={() => setOpenWarningModal((prev) => !prev)}
            data-testid={TESTIDS.WARNING_MODAL_BUTTON}
          >
            {MESSAGE_STRINGS['WarningModal.okay']}
          </GeneralButton>
        </Box>
      </MainModalContainer>
    </ModalBackground>
  );
};
WarningModal.propTypes = {
  warningMessages: PropTypes.array,
  setOpenWarningModal: PropTypes.func,
};
export default WarningModal;
