import React from 'react';
import infoIcon from '../../assets/img/infoIcon.svg?url';
import whiteDownloadIcon from '../../assets/img/whiteDownloadIcon.svg?url';
import whiteUploadIcon from '../../assets/img/whiteUploadIcon.svg?url';
import { Box, ButtonBase, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { MESSAGE_STRINGS, TESTIDS } from '../../constants/OeeConstants';
import CustomTooltip from '../../styledComponents/CustomTooltip';
import HeaderBox from '../../styledComponents/HeaderBox';
import ErrorList from '../ErrorList';

const CustomImg = styled('img')(() => ({
  width: '1.846rem',
  height: '1.846rem',
}));

const ToolTipTypography = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  marginLeft: '0.702rem',
}));

const SecondaryExportHeader = ({
  fetchDownloadUrl,
  onSecondaryUpload,
  isUploadError,
  errorList,
  removeUploadButton,
}) => {
  return (
    <Box width={'100%'} data-testid={TESTIDS.SECONDARY_HEADER}>
      <HeaderBox>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            height: '4rem',
          }}
        >
          <ButtonBase
            onClick={fetchDownloadUrl}
            data-testid={'download'}
            disableRipple
          >
            <Box
              sx={{
                display: 'flex',
                marginRight: removeUploadButton ? '1.09rem' : '',
              }}
            >
              <CustomImg src={whiteDownloadIcon} alt="download icon" />
              <ToolTipTypography>
                {MESSAGE_STRINGS['TARGETS.exportXlsx']}
              </ToolTipTypography>
            </Box>
          </ButtonBase>
          {!removeUploadButton ? (
            <ButtonBase
              onClick={onSecondaryUpload}
              data-testid={TESTIDS.SECONDARY_UPLOAD_BTN}
              disableRipple
            >
              <Box sx={{ display: 'flex', marginLeft: '1.331rem' }}>
                <CustomImg src={whiteUploadIcon} alt="upload icon" />
                <ToolTipTypography>
                  {MESSAGE_STRINGS['TARGETS.uploadXlsx']}
                </ToolTipTypography>
              </Box>
            </ButtonBase>
          ) : null}
          {!removeUploadButton ? (
            <CustomTooltip
              title={MESSAGE_STRINGS['TARGETS_UPLOAD.message']}
              placement="top-end"
              data-testid={TESTIDS.TOOlTIP}
            >
              <img
                style={{
                  margin: '0rem 1.25rem 0rem 0.625rem',
                  width: '1rem',
                  height: '1rem',
                }}
                src={infoIcon}
                alt="info icon"
              />
            </CustomTooltip>
          ) : null}
        </Box>
        {isUploadError && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ErrorList errorList={errorList} />
          </Box>
        )}
      </HeaderBox>
    </Box>
  );
};

SecondaryExportHeader.propTypes = {
  fetchDownloadUrl: PropTypes.func,
  onSecondaryUpload: PropTypes.func,
  isUploadError: PropTypes.bool,
  errorList: PropTypes.array,
  removeUploadButton: PropTypes.bool,
  isValidationSuccess: PropTypes.bool,
  removeExisitingFileData: PropTypes.func,
};

export default SecondaryExportHeader;
