import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ACCORDION_STATUS,
  OEE_ACCORDIONS,
  OEE_ID,
  MESSAGE_STRINGS,
  QUERY_CONSTANTS,
  DEVIATION_THRESHOLD_CONSTANTS,
  TESTIDS,
} from '../../constants/OeeConstants';
import Accordian from '../../components/Accordion/index';
import infoIcon from '../../assets/img/infoIcon.svg?url';
import DownArrow from '../../assets/img/downArrow.svg';
import GeneralButton from '../../components/GeneralButton';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import { Box, Typography, styled } from '@mui/material';
import AccordionStatus from '../../components/AccordionStatus';
import LoadingIndicator from '../../components/LoadingIndicator';
import DeviationSlider from '../../components/Slider';
import './DeviationThreshold.css';
import { useMutation, useQuery } from 'react-query';
import {
  getDeviationThresholds,
  updateDeviationThresholds,
} from '../../utils/apiHelpers';
import {
  convertCase,
  onApiErrorToastHandler,
  onApiSuccessToastHandler,
} from '../../utils/helpers';
import { useToastContext } from '../../context/toastContext';
import CustomTooltip from '../../styledComponents/CustomTooltip';
import CustomBackdrop from '../../styledComponents/CustomBackdrop';
import AccordionTitle from '../../styledComponents/AccordianTitle';
import FooterBox from '../../styledComponents/FooterBox';

const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '3.5rem',
  background: theme.palette.background.darkishBlackBlue,
  borderTop: `1px solid ${theme.palette.border.hierarchyTableGrey}`,
  borderBottom: `1px solid ${theme.palette.border.wrapperGrey}`,
  alignItems: 'center',
}));

const MetricBox = styled(Box)(() => ({
  width: '20%',
  paddingLeft: '1.25rem',
}));

const DeviationBox = styled(Box)(() => ({
  width: '20%',
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  fontWeight: '400',
  color: theme.palette.text.lightUnitGrey,
}));

const MetricTypography = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  fontWeight: '400',
  lineHeight: '1.25rem',
}));

const PercentTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '0.75rem',
  fontWeight: '700',
  lineHeight: '1.02rem',
  color: theme.palette.text.primary,
}));

const ColorBoxTypography = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'weight',
})(({ weight, theme }) => {
  let fontWeight = '700';
  if (weight === '400') {
    fontWeight = '400';
  }
  return {
    fontFamily: 'Open Sans',
    fontSize: '0.875rem',
    fontWeight: fontWeight,
    lineHeight: '1.192rem',
    color: theme.palette.text.primary,
  };
});

const SliderLabel = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  fontWeight: '400',
  lineHeight: '1.19rem',
  color: theme.palette.text.primary,
  height: '1.188rem',
}));

const DeviationRangeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: '0.31rem',
  width: '8rem',
  padding: '0.5rem',
  background: theme.customColors.blackishGrey,
  border: `1px solid ${theme.customColors.wrapperGrey}`,
  borderRadius: '0.25rem',
}));

const ColorBlock = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color, theme }) => {
  let bgColor = theme.customColors.white;
  if (color === theme.palette.background.errorColor) {
    bgColor = theme.palette.background.errorColor;
  } else if (color === theme.palette.background.tangerineColor) {
    bgColor = theme.palette.background.tangerineColor;
  } else if (color === theme.customColors.green) {
    bgColor = theme.customColors.green;
  }
  return {
    width: '0.3rem',
    height: '0.875rem',
    margin: 'auto 0px',
    background: bgColor,
  };
});

const DeviationContentBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'deviationTypeIdx' && prop !== 'length',
})(({ deviationTypeIdx, theme, length }) => {
  if (deviationTypeIdx !== length - 1) {
    return {
      height: '12rem',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderBottom: `1px solid ${theme.palette.border.wrapperGrey}`,
    };
  } else {
    return {
      height: '12rem',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    };
  }
});

const DeviationThreshold = ({
  onExpandUpdate = () => null,
  isExpanded = false,
  fetchConfigStatuses,
}) => {
  const [accordionStatus, setAccordionStatus] = useState(
    ACCORDION_STATUS.NOT_STARTED
  );
  const { rxjsState } = useRxjsState();
  const [entityId, setEntityId] = useState(rxjsState.plantId);

  useEffect(() => {
    if (rxjsState.plantId) {
      setEntityId(rxjsState.plantId);
    }
  }, [rxjsState.plantId]);

  const [DTResponse, setDTResponse] = useState([]);
  const [redColorCodeValues, setRedColorCodeValues] = useState({
    OEE: [-100, -16],
    Availability: [-100, -16],
    Performance: [-100, -16],
    Quality: [-100, -16],
  });
  const [amberColorCodeValues, setAmberColorCodeValues] = useState({
    OEE: [-15, -5],
    Availability: [-15, -5],
    Performance: [-15, -5],
    Quality: [-15, -5],
  });
  const [greenColorCodeValues, setGreenColorCodeValues] = useState({
    OEE: [-5, 0],
    Availability: [-5, 0],
    Performance: [-5, 0],
    Quality: [-5, 0],
  });
  const { toastDispatch } = useToastContext();

  const {
    isLoading: isDeviationThresholdsLoading,
    isFetching: isDeviationThresholdsFetching,
  } = useQuery(
    [QUERY_CONSTANTS.GET_DEVIATION_THRESHOLDS, entityId],
    async () => {
      return getDeviationThresholds(entityId);
    },
    {
      onSuccess: (deviationsResponse) => {
        setDTResponse(deviationsResponse.deviations);
        const status = convertCase(deviationsResponse.status);
        setAccordionStatus(status);
        const deviationsList = deviationsResponse.deviations;

        deviationsList.forEach((element, index) => {
          let metricName = DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[index];
          for (let metric of element[metricName]) {
            let limits = [];
            limits.push(metric.lowerBound);
            if (metric.label === DEVIATION_THRESHOLD_CONSTANTS.COLORS.AMBER) {
              limits.push(metric.upperBound + 1);
            } else {
              limits.push(metric.upperBound);
            }
            let metricToModify = {};
            metricToModify[metricName] = limits;

            if (metric.label === DEVIATION_THRESHOLD_CONSTANTS.COLORS.RED) {
              setRedColorCodeValues((prevColors) => ({
                ...prevColors,
                ...metricToModify,
              }));
              limits = [];
            } else if (
              metric.label === DEVIATION_THRESHOLD_CONSTANTS.COLORS.AMBER
            ) {
              setAmberColorCodeValues((prevColors) => ({
                ...prevColors,
                ...metricToModify,
              }));
              limits = [];
            } else if (
              metric.label === DEVIATION_THRESHOLD_CONSTANTS.COLORS.GREEN
            ) {
              setGreenColorCodeValues((prevColors) => ({
                ...prevColors,
                ...metricToModify,
              }));
              limits = [];
            }
          }
        });
      },
      onError: () => onApiErrorToastHandler(toastDispatch),
      retry: false,
      enabled: Boolean(entityId),
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: deviationsMutate, isLoading: isDeviationsUpdateLoading } =
    useMutation(
      [QUERY_CONSTANTS.UPDATE_DEVIATION_THRESHOLDS, entityId],
      async (reqBody) => {
        return updateDeviationThresholds(reqBody);
      },
      {
        onSuccess: (successMessage) => {
          const status = convertCase(successMessage.status);
          setAccordionStatus(status);
          fetchConfigStatuses();
          onApiSuccessToastHandler(toastDispatch);
        },
        onError: () => onApiErrorToastHandler(toastDispatch),
        enabled: Boolean(entityId),
      }
    );

  const handleSave = () => {
    let updatedResponse = [];

    DTResponse.forEach((_res, index) => {
      let itemsToUpdate = {};
      itemsToUpdate[DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[index]] = [
        {
          label: DEVIATION_THRESHOLD_CONSTANTS.COLORS.RED,
          lowerBound:
            redColorCodeValues[
              DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[index]
            ][0],
          upperBound:
            redColorCodeValues[
              DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[index]
            ][1],
        },
        {
          label: DEVIATION_THRESHOLD_CONSTANTS.COLORS.AMBER,
          lowerBound:
            amberColorCodeValues[
              DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[index]
            ][0],
          upperBound:
            amberColorCodeValues[
              DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[index]
            ][1] - 1,
        },
        {
          label: DEVIATION_THRESHOLD_CONSTANTS.COLORS.GREEN,
          lowerBound:
            greenColorCodeValues[
              DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[index]
            ][0],
          upperBound:
            greenColorCodeValues[
              DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[index]
            ][1],
        },
      ];
      updatedResponse.push(itemsToUpdate);
    });

    const reqBody = {
      entityId: entityId,
      itemsToBeUpdated: updatedResponse,
    };
    deviationsMutate(reqBody);
  };
  const backdropLoader =
    isDeviationThresholdsLoading ||
    isDeviationThresholdsFetching ||
    isDeviationsUpdateLoading;

  return (
    <Accordian
      id={OEE_ID.OEE_DEVIATION_THRESHOLD}
      expanded={isExpanded}
      onChange={() => {
        onExpandUpdate(OEE_ACCORDIONS.DEVIATION_THRESHOLD);
      }}
    >
      <Accordian.AccordionSummary
        aria-controls="=deviations-content"
        id="deviation-threshold-content"
        expandIcon={<DownArrow height={9} width={16} />}
        data-testid={TESTIDS.DEVIATION_ACCORDION}
      >
        <Box
          sx={{ flexGrow: 1, whiteSpace: 'nowrap' }}
          className="oee-accordion-summary-container"
        >
          <AccordionTitle>
            {MESSAGE_STRINGS['DEVIATION_THRESHOLD_ACCORDION_HEADER']}
          </AccordionTitle>
          <CustomTooltip
            title={MESSAGE_STRINGS['DEVIATION.info']}
            arrow
            placement="top-start"
          >
            <img
              className="oee-deviation-info-icon"
              src={infoIcon}
              alt="info icon"
            />
          </CustomTooltip>
        </Box>
        <AccordionStatus
          type={OEE_ACCORDIONS.DEVIATION_THRESHOLD}
          status={accordionStatus}
        />
      </Accordian.AccordionSummary>
      <Accordian.AccordionDetails data-testid={TESTIDS.DEVIATION_CONTENT}>
        <Box sx={{ width: '100%' }}>
          <HeaderBox>
            <MetricBox>
              <CustomTypography>
                {MESSAGE_STRINGS['HEADER.Metric']}
              </CustomTypography>
            </MetricBox>
            <DeviationBox>
              <CustomTypography>
                {MESSAGE_STRINGS['HEADER.Deviation_from_target']}
              </CustomTypography>
            </DeviationBox>
          </HeaderBox>
          <Box className="oee-thresholds-height">
            {DTResponse.map((_deviationType, deviationTypeIdx) => {
              return (
                <DeviationContentBox
                  key={deviationTypeIdx}
                  deviationTypeIdx={deviationTypeIdx}
                  length={DTResponse.length}
                >
                  <Box className="oee-row1">
                    <Box className="oee-row1-child1 ">
                      <MetricTypography>
                        {
                          DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[
                            deviationTypeIdx
                          ]
                        }
                      </MetricTypography>
                    </Box>
                    <Box className="oee-row1-child2">
                      <Box className="oee-slider-container">
                        <Box
                          className="oee-left-percent-box"
                          data-testid={TESTIDS.MIN_VAL}
                        >
                          <PercentTypography>
                            {DEVIATION_THRESHOLD_CONSTANTS.MIN_LIMIT}
                          </PercentTypography>
                        </Box>
                        <Box className="oee-slider-box">
                          <DeviationSlider
                            setPoints={
                              amberColorCodeValues[
                                DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[
                                  deviationTypeIdx
                                ]
                              ]
                            }
                            updateSetPoints={(result) => {
                              let metricToModify = {};
                              metricToModify[
                                DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[
                                  deviationTypeIdx
                                ]
                              ] = result;
                              setAmberColorCodeValues((prevColors) => ({
                                ...prevColors,
                                ...metricToModify,
                              }));
                            }}
                            updateRedColorCodeValues={(result) => {
                              let metricToModify = {};
                              metricToModify[
                                DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[
                                  deviationTypeIdx
                                ]
                              ] = result;
                              setRedColorCodeValues((prevColors) => ({
                                ...prevColors,
                                ...metricToModify,
                              }));
                            }}
                            updateGreenColorCodeValues={(result) => {
                              let metricToModify = {};
                              metricToModify[
                                DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[
                                  deviationTypeIdx
                                ]
                              ] = result;
                              setGreenColorCodeValues((prevColors) => ({
                                ...prevColors,
                                ...metricToModify,
                              }));
                            }}
                            updateAccordionStatus={setAccordionStatus}
                          />
                        </Box>
                        <Box
                          className="oee-right-percent-box"
                          data-testid={TESTIDS.MAX_VAL}
                        >
                          <PercentTypography>
                            {DEVIATION_THRESHOLD_CONSTANTS.MAX_LIMIT}
                          </PercentTypography>
                        </Box>
                        <Box className="oee-slider-label-box">
                          <SliderLabel>
                            {DEVIATION_THRESHOLD_CONSTANTS.SLIDER_LABEL}
                          </SliderLabel>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="oee-row2">
                    <Box className="oee-row2-child">
                      <Box className="oee-color-box-container ">
                        {DTResponse.length > 0 &&
                          DTResponse[deviationTypeIdx][
                            DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[
                              deviationTypeIdx
                            ]
                          ].map((colors, idx) => (
                            <DeviationRangeBox key={idx}>
                              <ColorBlock color={colors.color} />
                              <ColorBoxTypography>
                                {colors.label ===
                                  DEVIATION_THRESHOLD_CONSTANTS.COLORS.RED &&
                                  `${
                                    redColorCodeValues[
                                      DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[
                                        deviationTypeIdx
                                      ]
                                    ][0]
                                  }%`}
                                {colors.label ===
                                  DEVIATION_THRESHOLD_CONSTANTS.COLORS.AMBER &&
                                  `${
                                    amberColorCodeValues[
                                      DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[
                                        deviationTypeIdx
                                      ]
                                    ][0]
                                  }%`}
                                {colors.label ===
                                  DEVIATION_THRESHOLD_CONSTANTS.COLORS.GREEN &&
                                  `${
                                    greenColorCodeValues[
                                      DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[
                                        deviationTypeIdx
                                      ]
                                    ][0]
                                  }%`}
                              </ColorBoxTypography>
                              <ColorBoxTypography weight={'400'}>
                                to
                              </ColorBoxTypography>
                              <ColorBoxTypography>
                                {colors.label ===
                                  DEVIATION_THRESHOLD_CONSTANTS.COLORS.RED &&
                                  `${
                                    redColorCodeValues[
                                      DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[
                                        deviationTypeIdx
                                      ]
                                    ][1]
                                  }%`}
                                {colors.label ===
                                  DEVIATION_THRESHOLD_CONSTANTS.COLORS.AMBER &&
                                  `${
                                    amberColorCodeValues[
                                      DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[
                                        deviationTypeIdx
                                      ]
                                    ][1] - 1
                                  }%`}
                                {colors.label ===
                                  DEVIATION_THRESHOLD_CONSTANTS.COLORS.GREEN &&
                                  `${
                                    greenColorCodeValues[
                                      DEVIATION_THRESHOLD_CONSTANTS.OEE_METRIC[
                                        deviationTypeIdx
                                      ]
                                    ][1]
                                  }%`}
                              </ColorBoxTypography>
                            </DeviationRangeBox>
                          ))}
                      </Box>
                    </Box>
                  </Box>
                </DeviationContentBox>
              );
            })}
          </Box>
          <FooterBox>
            <GeneralButton
              text="Save"
              sx={{ width: '7.5rem' }}
              onClick={handleSave}
              data-testid={TESTIDS.DEVIATION_SAVE}
            />
          </FooterBox>
        </Box>
      </Accordian.AccordionDetails>
      <Box
        sx={{ display: 'flex', maxHeight: '100%', flexDirection: 'column' }}
        data-testid={TESTIDS.BACKDROP}
      >
        <CustomBackdrop open={backdropLoader}>
          <LoadingIndicator />
        </CustomBackdrop>
      </Box>
    </Accordian>
  );
};

export default DeviationThreshold;

DeviationThreshold.propTypes = {
  onExpandUpdate: PropTypes.func,
  isExpanded: PropTypes.bool,
  fetchConfigStatuses: PropTypes.func,
};
