import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ACCORDION_STATUS,
  FILE_CONTENT_TYPES,
  FILE_UPLOAD_STATUSES,
  MESSAGE_STRINGS,
} from '../../constants/OeeConstants';
import { Box } from '@mui/material';
import { convertCase } from '../../utils/helpers';
import ExportHeader from '../ExportHeader';
import UploadBox from '../UploadBox';

const InitialUploader = ({
  fetchDownloadUrl,
  entityId,
  errorList,
  isValidateError,
  validate,
  resetValidate,
  updateAccordionStatus,
  getPresignedUploadUrl,
  utility,
}) => {
  const [isFilePreparing, setIsFilePreparing] = useState(false);
  const getUploadUrl = async (file) => {
    const params = {
      utility: utility,
      contentType: FILE_CONTENT_TYPES.XLSX,
      fileName: file,
      entityId: entityId,
    };
    const result = await getPresignedUploadUrl(params);
    return result.data;
  };

  const getUploadParams = async (params) => {
    const { file, meta } = params;
    const uploadUrl = await getUploadUrl(meta.name);
    return {
      body: file,
      url: uploadUrl,
      meta,
      method: 'PUT',
      headers: {
        'Content-Type': FILE_CONTENT_TYPES.XLSX,
      },
    };
  };

  const handleChangeStatus = (fileDetails, status) => {
    if (
      status === FILE_UPLOAD_STATUSES.PREPARING ||
      status === FILE_UPLOAD_STATUSES.GETTING_UPLOAD_PARAMS ||
      status === FILE_UPLOAD_STATUSES.UPLOADING
    ) {
      updateAccordionStatus(convertCase(ACCORDION_STATUS.IN_PROGRESS));
      setIsFilePreparing(true);
    }
    if (status === FILE_UPLOAD_STATUSES.DONE) {
      validate(fileDetails.meta.name);
    }
  };
  const handleReupload = (fileWithMeta) => {
    fileWithMeta.remove();
    resetValidate();
    setIsFilePreparing(false);
  };
  return (
    <Box>
      <ExportHeader
        fetchDownloadUrl={fetchDownloadUrl}
        displayName={MESSAGE_STRINGS['TARGETS.exportXlsx']}
        isValidateError={isValidateError}
        errorList={errorList}
      />
      <UploadBox
        getUploadParams={getUploadParams}
        handleChangeStatus={handleChangeStatus}
        handleReupload={handleReupload}
        isValidateError={isValidateError}
        utility={utility}
        isFilePreparing={isFilePreparing}
      />
    </Box>
  );
};

InitialUploader.propTypes = {
  fetchDownloadUrl: PropTypes.func,
  entityId: PropTypes.string,
  errorList: PropTypes.array,
  isValidateError: PropTypes.bool,
  validate: PropTypes.func,
  resetValidate: PropTypes.func,
  updateAccordionStatus: PropTypes.func,
  getPresignedUploadUrl: PropTypes.func,
  utility: PropTypes.string,
};

export default InitialUploader;
