import { Box, styled } from '@mui/material';
import React from 'react';

const FooterBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.footerGrey,
  borderTop: `0.063em solid ${theme.palette.border.wrapperGrey}`,
  height: '3.5em',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingRight: '1rem',
}));

export default FooterBox;
