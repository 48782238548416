import { styled } from '@mui/material';
import { TreeItem } from '@mui/lab';
import PropTypes from 'prop-types';
import { useTreePanelContext } from '../../../components/PanelTreeTable/TreePanel';
import WarningIcon from '../../../assets/img/warningIconV2.svg';

const CustomTreeItem = styled(TreeItem)(() => ({
  '& .MuiTreeItem-content > .MuiTreeItem-iconContainer': {
    width: '0rem ',
  },
  '& .MuiTreeItem-root': {
    marginBottom: '0em ',
  },
  '& .MuiTreeItem-content': {
    padding: '0.625em 0em',
    cursor: 'default',
  },
  '& .MuiTreeItem-label': {
    paddingLeft: '0em !important',
  },
  '& .MuiTreeItem-label:hover': {
    background: 'transparent',
  },
  '& .MuiTreeItem-content.Mui-selected': {
    background: 'transparent',
  },
  '& .MuiTreeItem-content.Mui-selected.Mui-focused': {
    background: 'transparent',
    backgroundColor: 'transparent ',
  },
  '& .MuiTreeItem-content.Mui-selected:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-selected > .MuiTreeItem-content': {
    background: 'transparent',
  },
  '& .MuiTreeItem-group': {
    marginLeft: '0',
  },
  '& .MuiTypography-body1': {
    lineHeight: '1.25em',
  },
  '& .MuiTreeItem-content.Mui-focused': {
    backgroundColor: 'transparent',
  },
}));

const DisabledBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasChildren',
})(({ hasChildren }) => ({
  position: 'relative',
  top: '0.8em',
  marginBottom: '-1em',
  left: hasChildren ? '-2em' : '-1em',
  display: 'flex',
}));

function RenderData({
  nodes,
  index,
  LabelComponent = () => null,
  selected,
  depth = 0,
  treeWidth,
}) {
  const hasChildren = Array.isArray(nodes.entityChildren);

  const { onMouseEnter, onMouseLeave } = useTreePanelContext();
  const noIcon = ['Plant', 'Area', 'Cell'];

  return (
    <>
      {nodes.isDisabled && (
        <DisabledBox hasChildren={hasChildren}>
          <WarningIcon height={16} />
        </DisabledBox>
      )}
      <CustomTreeItem
        role="listitem"
        id={nodes.entityId}
        key={nodes.entityId}
        className={`${
          (noIcon.includes(nodes.entityType) || !hasChildren) && 'noIcon'
        }`}
        onMouseOver={(e) => onMouseEnter(e)}
        onMouseLeave={onMouseLeave}
        nodeId={nodes.entityId}
        label={
          <LabelComponent
            {...nodes}
            isHeadingDisabled={nodes.isDisabled}
            depth={depth}
            nodes={nodes}
          />
        }
      >
        {hasChildren
          ? nodes.entityChildren.map((node, i) => (
              <RenderData
                key={`${index} - ${node.entityName}`}
                nodes={node}
                index={i}
                selected={selected}
                LabelComponent={LabelComponent}
                depth={depth + 1}
                treeWidth={treeWidth}
              />
            ))
          : null}
      </CustomTreeItem>
    </>
  );
}

RenderData.propTypes = {
  nodes: PropTypes.shape({
    entityId: PropTypes.string,
    entityType: PropTypes.string,
    isDisabled: PropTypes.bool,
    entityName: PropTypes.string,
    type: PropTypes.string,
    entityChildren: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  treeWidth: PropTypes.number,
  index: PropTypes.number,
  depth: PropTypes.number,
  LabelComponent: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string),
};

export default RenderData;
