import { Box, styled, Typography } from '@mui/material';
import ExclamationMark from '../../assets/img/dangerIconV2.svg';
import PropTypes from 'prop-types';

const ErrorMessageTypography = styled(Typography)(({ theme }) => ({
  fontfamily: 'Open Sans',
  fontStyle: 'normal',
  color: theme.palette.text.primary,
  fontSize: '0.75rem ',
  fontWeight: 400,
  lineHeight: '1rem',
}));

const ErrorContainer = styled(Box)(() => ({
  display: 'Flex',
  marginTop: '0.438rem',
}));

const ErrorIconContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '0.438rem',
}));

const FieldError = ({ errorMessage }) => {
  return (
    <ErrorContainer>
      <ErrorIconContainer>
        <ExclamationMark />
      </ErrorIconContainer>
      <ErrorMessageTypography>{errorMessage}</ErrorMessageTypography>
    </ErrorContainer>
  );
};
FieldError.propTypes = {
  errorMessage: PropTypes.string,
};

export default FieldError;
