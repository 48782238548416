import { Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const ListPanelContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.blackGrey,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const ListPanelHeader = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.darkishBlackBlue,
  color: theme.palette.text.lightUnitGrey,
  minHeight: '3.5rem',
  border: `1px solid ${theme.palette.border.darkGrey}`,
  display: 'flex',
  alignItems: 'center',
  padding: '0.25rem 0rem 0.25rem 0.625rem',
  borderWidth: '0px 0px 0px 0px',
  '& .MuiTypography-subtitle2': {
    lineHeight: '1.188rem',
  },
}));

const ListPanelHeaderTitle = styled(Typography)(() => ({
  marginLeft: '0.625em',
}));

const ListPanelListContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.selected.darkGrayishBlue}`,
  borderWidth: '1px 1px 0px 1px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: '100%',
  '& .MuiTypography-body1': {
    lineHeight: '1.188rem',
  },
  '& .MuiTypography-subtitle2': {
    lineHeight: '1.188rem',
  },
}));

function ListPanel({ heading = '', children }) {
  return (
    <ListPanelContainer>
      <ListPanelHeader>
        <ListPanelHeaderTitle variant="subtitle2">
          {heading}
        </ListPanelHeaderTitle>
      </ListPanelHeader>
      <ListPanelListContainer>{children}</ListPanelListContainer>
    </ListPanelContainer>
  );
}

ListPanel.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node,
};

export default ListPanel;
