import { Box, styled, Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import Dropzone from 'react-dropzone-uploader';
import {
  FILE_CONTENT_TYPES,
  MESSAGE_STRINGS,
  QUERY_CONSTANTS,
} from '../../constants/OeeConstants';
import UploadInput from '../UploadInput';
import UploadPreview from '../UploadPreview';

const StyledBox = styled(Box)(({ theme }) => ({
  height: '17.688rem',
  width: '50rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px dashed ${theme.customColors.saveGreen}`,
}));

const UploadBox = forwardRef((props, ref) => {
  const {
    getUploadParams,
    handleChangeStatus,
    isValidateError,
    isFilePreparing = false,
    handleReupload,
    utility,
  } = props;
  return (
    <Box
      sx={{
        height: '32.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <StyledBox>
        <Box data-testid="dropzone">
          <Dropzone
            ref={ref}
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            maxFiles={1}
            SubmitButtonComponent={() => null}
            PreviewComponent={(previewProps) => (
              <UploadPreview
                previewComponentProps={previewProps}
                handleReupload={handleReupload}
                isValidateError={isValidateError}
                isFilePreparing={isFilePreparing}
                utility={utility}
              />
            )}
            InputComponent={UploadInput}
            accept={FILE_CONTENT_TYPES.XLSX}
            multiple={false}
            canRestart
          />
          {!isFilePreparing && !isValidateError && (
            <Grid item>
              <Typography variant="h6" sx={{ textAlign: 'center' }}>
                {utility === QUERY_CONSTANTS.IDEAL_RR_UTILITY
                  ? MESSAGE_STRINGS['IRR.uploadXlsxPerProdDay']
                  : MESSAGE_STRINGS['TARGETS.uploadXlsxPerProdDay']}
              </Typography>
            </Grid>
          )}
        </Box>
      </StyledBox>
    </Box>
  );
});

export default UploadBox;
UploadBox.displayName = UploadBox;
UploadBox.propTypes = {
  getUploadParams: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  handleReupload: PropTypes.func,
  isFilePreparing: PropTypes.bool,
  isValidateError: PropTypes.bool,
  ref: PropTypes.element,
  utility: PropTypes.string,
};
