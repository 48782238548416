import React from 'react';
import PropTypes from 'prop-types';
import { InputBase, MenuItem, Select, styled, Typography } from '@mui/material';
import DownArrow from '../../assets/img/downArrow.svg';
import {
  DROPDOWN_CONSTANTS,
  MESSAGE_STRINGS,
} from '../../constants/OeeConstants';

function DownArrowIcon(props) {
  return <DownArrow height={8} width={12} {...props} />;
}

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontfamily: 'Open Sans',
  fontStyle: 'normal',
  color: theme.palette.text.primary,
  fontSize: '0.875rem ',
  fontWeight: 400,
  lineHeight: '1.25rem',
}));

const CustomizedSelect = styled(Select)(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.primary,
  height: '2rem',
}));

const CustomizedInputBase = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'borderRadius' && prop !== 'error',
})(({ theme, borderRadius: dropdownRadius, error }) => ({
  width: '100%',
  '& .Mui-disabled': {
    color: theme.palette.background.shuttleGray,
  },
  color: theme.palette.background.shuttleGray,
  '& .MuiSelect-iconOutlined': {
    marginRight: '0.5em',
    top: 'inherit',
  },
  '& .MuiSelect-icon.Mui-disabled': {
    filter: 'opacity(0.5)',
    '& path': { fill: theme.palette.background.shuttleGray },
  },
  '& .MuiInputBase-input': {
    border: `.0625em solid ${
      error ? theme.palette.border.lavaRed : theme.palette.border.lightWhiteGrey
    }`,
    padding: 3.5,
    width: '100%',
    paddingLeft: '1em',
    '&.MuiSelect-select': {
      borderRadius: dropdownRadius,
      backgroundColor: theme.palette.background.configLighterDark,
    },
    '&:focus': {
      border: `.0625em solid ${theme.palette.border.lightWhiteGrey}`,
    },
    '&[aria-expanded=true]': {
      border: `.0625em solid ${theme.palette.selected.greenSelect}`,
      color: theme.palette.selected.greenSelect,
    },
    '&.Mui-disabled': {
      WebkitTextFillColor: 'inherit', // Was overriding given disabled color
    },
  },
  '& .Mui-error': {
    borderColor: theme.palette.text.errorText,
    '&:focus': {
      borderColor: theme.palette.text.errorText,
    },
  },
}));

const paperStyles = {
  '& .MuiMenuItem-root.Mui-selected, .MuiMenuItem-root:hover,.MuiMenuItem-root.Mui-selected:hover ':
    {
      backgroundColor: (theme) => theme.palette.background.gunmetalGrey,
    },
};
const menuStyles = { maxHeight: '15.75em' };

function GeneralDropdown({
  value = '',
  children,
  placeholderText = MESSAGE_STRINGS['GeneralDropdown.default.placeholder'],
  borderRadius = 20,
  disabled = false,
  error = false,
  onChange = () => null,
  ...other
}) {
  return (
    <CustomizedSelect
      data-testid="input-dropdown"
      error={error}
      onChange={onChange}
      disabled={disabled}
      value={value}
      label={placeholderText}
      defaultValue={DROPDOWN_CONSTANTS.DEFAULT_VALUE}
      input={<CustomizedInputBase borderRadius={borderRadius} />}
      IconComponent={DownArrowIcon}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          sx: paperStyles,
        },
        sx: menuStyles,
      }}
      {...other}
    >
      <MenuItem
        disabled
        value={DROPDOWN_CONSTANTS.DEFAULT_VALUE}
        sx={{ display: 'none' }}
      >
        <CustomTypography>{placeholderText}</CustomTypography>
      </MenuItem>
      {children}
    </CustomizedSelect>
  );
}
GeneralDropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  placeholderText: PropTypes.string,
  borderRadius: PropTypes.number,
  menuHeight: PropTypes.string,
  error: PropTypes.bool,
};

export default GeneralDropdown;

// Use FormControl component to give minWidth
// Pass options has children using MenuItem

// Example:
// <SelectDropdown value="SELECT" onChange={}>
// 	<MenuItem value={}> Shift 1 </MenuItem>
// 	<MenuItem value={}>Shift 2</MenuItem>
// </SelectDropdown>
// :Example
