import { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import PropTypes from 'prop-types';
import Accordion from '../../components/Accordion/index';
import DownArrow from '../../assets/img/downArrow.svg';
import { Box, MenuItem, styled, Typography } from '@mui/material';
import {
  ACCORDION_STATUS,
  ACTUALS_CONSTANTS,
  ACTUALS_ID,
  ACTUALS_LABELS,
  DEFAULT_API_RESPONSE,
  DROPDOWN_CONSTANTS,
  OEE_ACCORDIONS,
  MESSAGE_STRINGS,
  QUERY_CONSTANTS,
  TESTIDS,
} from '../../constants/OeeConstants';
import AccordionTitle from '../../styledComponents/AccordianTitle';
import AccordionStatus from '../../components/AccordionStatus';
import GeneralButton from '../../components/GeneralButton';
import TextField from '../../components/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';
import GeneralDropdown from '../../components/GeneralDropdown';
import FieldError from '../../components/FieldError';
import {
  convertCase,
  getDateTimeStringFromTotalMinutes,
  getTotalMinutesFromDateTimeString,
  onApiErrorToastHandler,
  onApiSuccessToastHandler,
  validateActualsEmptyFields,
  validateActualsfrequencyPublished,
  validateActualsRefreshRate,
} from '../../utils/helpers';
import { getActuals, updateActuals } from '../../utils/apiHelpers';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import { useToastContext } from '../../context/toastContext';
import CustomBackdrop from '../../styledComponents/CustomBackdrop';
import LoadingIndicator from '../../components/LoadingIndicator';
import FooterBox from '../../styledComponents/FooterBox';

const ActualsHeaderRow = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.darkishBlackBlue,
  borderTop: `0.063em solid ${theme.palette.border.hierarchyTableGrey}`,
  height: '3.5em',
  width: '100%',
  display: 'flex',
  paddingLeft: '1.125rem',
}));

const RowParameter = styled(Box)(() => ({
  width: '32%',
  display: 'flex',
  alignItems: 'center',
}));

const RowValue = styled(Box)(() => ({
  width: '68%',
  display: 'flex',
  alignItems: 'center',
}));

const PublishedParameter = styled(Box)(() => ({
  width: '60%',
  display: 'flex',
  alignItems: 'center',
}));

const PublishedValue = styled(Box)(() => ({
  width: '40%',
  display: 'flex',
  alignItems: 'center',
}));

const PublishContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
}));

const PublishLabel = styled(Box)(() => ({
  width: '20%',
  display: 'flex',
  alignItems: 'center',
}));

const PublishInputContainer = styled(Box)(() => ({
  width: '80%',
  display: 'flex',
}));

const CustomTypography = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'isHeading' && prop !== 'isMenuItem' && prop !== 'isButtonLabel',
})(({ theme, isHeading = false, isMenuItem = false, isButtonLabel }) => {
  let color = theme.palette.text.primary;
  if (isHeading) color = theme.palette.text.lightUnitGrey;
  if (isButtonLabel) color = '';
  let lineHeight = '1.25rem';
  if (isMenuItem) lineHeight = '1.1875rem';
  return {
    fontfamily: 'Open Sans',
    fontStyle: 'normal',
    color: color,
    fontSize: '0.875rem ',
    fontWeight: 400,
    lineHeight: lineHeight,
  };
});

const CustomRow = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.blackGrey,
  borderTop: `0.063em solid ${theme.palette.border.wrapperGrey}`,
  height: '3rem',
  width: '100%',
  display: 'flex',
  paddingLeft: '1.125rem',
}));

const ErrorRow = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.blackGrey,
  paddingLeft: '1.125rem',
  height: '3rem',
}));

const RowValueBox = styled(Box)(() => ({
  width: '10rem',
}));

const Actuals = ({
  onExpandUpdate = () => null,
  isExpanded = false,
  fetchConfigStatuses,
}) => {
  const { rxjsState } = useRxjsState();
  const [entityId, setEntityId] = useState(rxjsState.plantId);

  const { toastDispatch } = useToastContext();

  const [uom, setUom] = useState();

  const [accordionStatus, setAccordionStatus] = useState(
    ACCORDION_STATUS.NOT_STARTED
  );
  const [apiResponse, setApiResponse] = useState(DEFAULT_API_RESPONSE);

  const [selectedTimeRange, setSelectedTimeRange] = useState(
    DROPDOWN_CONSTANTS.DEFAULT_VALUE
  );

  const [refreshRateValue, setRefreshRateValue] = useState();

  const [selectedRefreshRateUnit, setSelectedRefreshRateUnit] = useState(
    DROPDOWN_CONSTANTS.DEFAULT_VALUE
  );

  const [refreshRateErrorMessage, setRefreshRateErrorMessage] = useState('');
  const [frequencyPublishedErrorMessage, setFrequencyPublishedErrorMessage] =
    useState('');

  const [timePublished, setTimePublished] = useState(null);

  const [frequencyPublished, setFrequencyPublished] = useState();

  const [actualsEmptyFields, setActualsEmptyFields] = useState([]);

  useEffect(() => {
    if (rxjsState.plantId) {
      setEntityId(rxjsState.plantId);
    }
  }, [rxjsState.plantId]);

  const handleTimeRangeSelection = (event) => {
    setAccordionStatus(ACCORDION_STATUS.IN_PROGRESS);
    setSelectedTimeRange(event.target.value);
    setActualsEmptyFields((prevActualsEmptyFields) => {
      return prevActualsEmptyFields.filter(
        (emptyField) => emptyField !== ACTUALS_LABELS.TIME_RANGE
      );
    });
  };

  const handleRefreshRateValueChange = (event) => {
    setAccordionStatus(ACCORDION_STATUS.IN_PROGRESS);
    const newValue = event.target.value.replace(/\D/g, '');
    setRefreshRateErrorMessage(
      validateActualsRefreshRate(newValue, selectedRefreshRateUnit)
    );
    setRefreshRateValue(newValue);
    setActualsEmptyFields((prevActualsEmptyFields) => {
      return prevActualsEmptyFields.filter(
        (emptyField) => emptyField !== ACTUALS_LABELS.REFRESH_RATE_VALUE
      );
    });
  };

  const handleRefreshRateUnitSelection = (event) => {
    setAccordionStatus(ACCORDION_STATUS.IN_PROGRESS);
    const newValue = event.target.value;
    setRefreshRateErrorMessage(
      validateActualsRefreshRate(refreshRateValue, newValue)
    );
    setSelectedRefreshRateUnit(newValue);
    setActualsEmptyFields((prevActualsEmptyFields) => {
      return prevActualsEmptyFields.filter(
        (emptyField) => emptyField !== ACTUALS_LABELS.REFRESH_RATE_UNIT
      );
    });
  };

  const handleTimePublishedChange = (newValue) => {
    setAccordionStatus(ACCORDION_STATUS.IN_PROGRESS);
    setTimePublished(newValue);
    setActualsEmptyFields((prevActualsEmptyFields) => {
      return prevActualsEmptyFields.filter(
        (emptyField) => emptyField !== ACTUALS_LABELS.TIME_PUBLISHED
      );
    });
  };

  const handleFrequencyPublishedChange = (event) => {
    setAccordionStatus(ACCORDION_STATUS.IN_PROGRESS);
    const newValue = event.target.value.replace(/\D/g, '');
    setFrequencyPublishedErrorMessage(
      validateActualsfrequencyPublished(newValue)
    );
    setFrequencyPublished(newValue);
    setActualsEmptyFields((prevActualsEmptyFields) => {
      return prevActualsEmptyFields.filter(
        (emptyField) => emptyField !== ACTUALS_LABELS.FREQUENCY_PUBLISHED
      );
    });
  };

  const { isLoading: isGetActualsLoading } = useQuery(
    [QUERY_CONSTANTS.GET_ACTUALS, entityId],
    async () => {
      setRefreshRateValue();
      setSelectedRefreshRateUnit(DROPDOWN_CONSTANTS.DEFAULT_VALUE);
      setSelectedTimeRange(DROPDOWN_CONSTANTS.DEFAULT_VALUE);
      return getActuals(entityId, QUERY_CONSTANTS.OEE_UTILITY);
    },
    {
      //On successful execution of api request
      onSuccess: (actualsResponse) => {
        const status = convertCase(actualsResponse.status);
        setAccordionStatus(status);
        setApiResponse(actualsResponse);

        const UoM = actualsResponse.actuals.find(
          (item) => item.label === ACTUALS_LABELS.UOM
        ).value;
        if (UoM) {
          setUom(convertCase(UoM));
        }

        const timeRange = actualsResponse.actuals.find(
          (item) => item.label === ACTUALS_LABELS.TIME_RANGE
        ).value;
        if (timeRange) {
          setSelectedTimeRange(timeRange);
        }

        const refreshRateValueFromApi = actualsResponse.actuals.find(
          (item) => item.label === ACTUALS_LABELS.REFRESH_RATE
        ).value;
        if (refreshRateValueFromApi) {
          setRefreshRateValue(refreshRateValueFromApi.toString());
        }

        const refreshRateUnit = actualsResponse.actuals.find(
          (item) => item.label === ACTUALS_LABELS.REFRESH_RATE
        ).unit;
        if (refreshRateUnit) {
          setSelectedRefreshRateUnit(refreshRateUnit);
        }

        const timePublishedFromApi = actualsResponse.actuals.find(
          (item) => item.label === ACTUALS_LABELS.TIME_PUBLISHED
        ).value;
        if (timePublishedFromApi) {
          setTimePublished(
            getDateTimeStringFromTotalMinutes(timePublishedFromApi)
          );
        }

        const frequencyPublishedFromApi = actualsResponse.actuals.find(
          (item) => item.label === ACTUALS_LABELS.FREQUENCY_PUBLISHED
        ).value;
        if (frequencyPublishedFromApi) {
          setFrequencyPublished(frequencyPublishedFromApi);
        }
      },
      onError: () => onApiErrorToastHandler(toastDispatch),
      retry: false,
      enabled: Boolean(entityId),
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: actualsMutate, isLoading: isActualsUpdating } = useMutation(
    [QUERY_CONSTANTS.UPDATE_ACTUALS],
    async (reqBody) => {
      return updateActuals(reqBody);
    },
    {
      onSuccess: (actualsResponse) => {
        const status = convertCase(actualsResponse.status.actuals);
        setAccordionStatus(status);
        fetchConfigStatuses();
        onApiSuccessToastHandler(toastDispatch);
      },
      onError: () => onApiErrorToastHandler(toastDispatch),
      enabled: Boolean(entityId),
    }
  );

  async function handleOnSave() {
    const { emptyFields } = validateActualsEmptyFields(
      selectedTimeRange,
      refreshRateValue,
      selectedRefreshRateUnit,
      timePublished,
      frequencyPublished
    );

    if (emptyFields) {
      setActualsEmptyFields(emptyFields);
    }
    const reqBody = {
      entityId: entityId,
      utility: QUERY_CONSTANTS.OEE_UTILITY,
      productionScheduleFrequency: frequencyPublished,
      productionScheduleTime: getTotalMinutesFromDateTimeString(timePublished),
      refreshRateUnit: selectedRefreshRateUnit,
      refreshRateValue: refreshRateValue,
      timeRangeValue: selectedTimeRange,
    };
    if (!emptyFields) {
      actualsMutate(reqBody);
    }
  }

  const renderSwitch = (actualsItem) => {
    switch (actualsItem.label) {
      case 'UoM':
        return <CustomTypography>{uom}</CustomTypography>;
      case 'Time Range':
        return (
          <RowValueBox>
            <GeneralDropdown
              data-testid={ACTUALS_ID.TIME_RANGE_SELECTION}
              value={selectedTimeRange}
              error={actualsEmptyFields.includes(ACTUALS_LABELS.TIME_RANGE)}
              onChange={handleTimeRangeSelection}
            >
              <MenuItem
                data-testid={ACTUALS_ID.TIME_RANGE_ITEMS}
                value={ACTUALS_CONSTANTS.TIME_RANGE_SHIFT}
              >
                <CustomTypography isMenuItem>
                  {MESSAGE_STRINGS['TIME_RANGE.shift']}
                </CustomTypography>
              </MenuItem>
              <MenuItem
                data-testid={ACTUALS_ID.TIME_RANGE_ITEMS}
                value={ACTUALS_CONSTANTS.TIME_RANG_PRODUCTION_DAY}
              >
                <CustomTypography isMenuItem>
                  {MESSAGE_STRINGS['TIME_RANGE.production_day']}
                </CustomTypography>
              </MenuItem>
            </GeneralDropdown>
          </RowValueBox>
        );
      case 'Refresh Rate':
        return (
          <Box sx={{ display: 'flex' }}>
            <RowValueBox>
              <TextField
                data-testid={ACTUALS_ID.REFRESH_RATE_TEXTFIELD}
                value={refreshRateValue}
                error={
                  Boolean(refreshRateErrorMessage) ||
                  actualsEmptyFields.includes(ACTUALS_LABELS.REFRESH_RATE_VALUE)
                }
                onChange={handleRefreshRateValueChange}
                placeholder="-"
              />
            </RowValueBox>
            <Box
              sx={{
                marginLeft: '1rem',
                display: 'flex',
                alignItems: 'center',
                width: '10rem',
              }}
            >
              <GeneralDropdown
                data-testid={ACTUALS_ID.REFRESH_RATE_SELECTION}
                value={selectedRefreshRateUnit}
                error={actualsEmptyFields.includes(
                  ACTUALS_LABELS.REFRESH_RATE_UNIT
                )}
                onChange={handleRefreshRateUnitSelection}
              >
                <MenuItem
                  data-testid={ACTUALS_ID.REFRESH_RATE_ITEMS}
                  value={ACTUALS_CONSTANTS.REFRESH_RATE_UNIT_SECONDS}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CustomTypography isMenuItem>
                      {MESSAGE_STRINGS['REFRESH_RATE.seconds']}
                    </CustomTypography>
                  </Box>
                </MenuItem>
                <MenuItem
                  data-testid={ACTUALS_ID.REFRESH_RATE_ITEMS}
                  value={ACTUALS_CONSTANTS.REFRESH_RATE_UNIT_MINUTES}
                >
                  <CustomTypography isMenuItem>
                    {MESSAGE_STRINGS['REFRESH_RATE.minutes']}
                  </CustomTypography>
                </MenuItem>
                <MenuItem
                  data-testid={ACTUALS_ID.REFRESH_RATE_ITEMS}
                  value={ACTUALS_CONSTANTS.REFRESH_RATE_UNIT_HOURS}
                >
                  <CustomTypography isMenuItem>
                    {MESSAGE_STRINGS['REFRESH_RATE.hours']}
                  </CustomTypography>
                </MenuItem>
              </GeneralDropdown>
            </Box>
          </Box>
        );
      case 'Time the Production Schedule is Published':
        return (
          <PublishContainer>
            <PublishLabel>
              <CustomTypography>
                {MESSAGE_STRINGS['TIME_PUBLISHED.label']}
              </CustomTypography>
            </PublishLabel>
            <PublishInputContainer>
              <RowValueBox>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    disableOpenPicker={true}
                    ampm={false}
                    openTo="hours"
                    views={['hours', 'minutes']}
                    inputFormat="HH:mm"
                    mask="__:__"
                    value={timePublished}
                    onChange={handleTimePublishedChange}
                    renderInput={(params) => (
                      <TextField
                        data-testid={ACTUALS_ID.TIME_PUBLISHED_TEXTFIELD}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'HH:MM',
                        }}
                        error={
                          actualsEmptyFields.includes(
                            ACTUALS_LABELS.TIME_PUBLISHED
                          ) || params.error
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </RowValueBox>
            </PublishInputContainer>
          </PublishContainer>
        );
      case 'Frequency the Production Schedule is Published':
        return (
          <PublishContainer>
            <PublishLabel>
              <CustomTypography>
                {MESSAGE_STRINGS['FREQUENCY_PUBLISHED.label']}
              </CustomTypography>
            </PublishLabel>
            <PublishInputContainer>
              <RowValueBox>
                <TextField
                  data-testid={ACTUALS_ID.FREQUENCY_PUBLISHED_TEXTFIELD}
                  value={frequencyPublished}
                  error={
                    Boolean(frequencyPublishedErrorMessage) ||
                    actualsEmptyFields.includes(
                      ACTUALS_LABELS.FREQUENCY_PUBLISHED
                    )
                  }
                  onChange={handleFrequencyPublishedChange}
                  placeholder="-"
                />
              </RowValueBox>
              <Box
                sx={{
                  marginLeft: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CustomTypography>
                  {MESSAGE_STRINGS['FREQUENCY_PUBLISHED.unit']}
                </CustomTypography>
              </Box>
            </PublishInputContainer>
          </PublishContainer>
        );
    }
  };

  const backdropLoader = isGetActualsLoading || isActualsUpdating;

  const getEmptyFiledsError = (actualsItemLabel) => {
    if (actualsItemLabel === ACTUALS_LABELS.REFRESH_RATE) {
      return (
        <Box sx={{ display: 'flex' }}>
          {actualsEmptyFields.includes(ACTUALS_LABELS.REFRESH_RATE_VALUE) && (
            <RowValueBox>
              <FieldError
                errorMessage={
                  MESSAGE_STRINGS['ERROR_MESSAGES.requiredFieldValidation']
                }
              />
            </RowValueBox>
          )}
          {actualsEmptyFields.includes(ACTUALS_LABELS.REFRESH_RATE_UNIT) && (
            <Box
              sx={{
                width: '10rem',
                marginLeft: actualsEmptyFields.includes(
                  ACTUALS_LABELS.REFRESH_RATE_VALUE
                )
                  ? '1rem'
                  : '11rem',
              }}
            >
              <FieldError
                errorMessage={
                  MESSAGE_STRINGS['ERROR_MESSAGES.requiredFieldValidation']
                }
              />
            </Box>
          )}
        </Box>
      );
    } else {
      return (
        <RowValueBox>
          <FieldError
            errorMessage={
              MESSAGE_STRINGS['ERROR_MESSAGES.requiredFieldValidation']
            }
          />
        </RowValueBox>
      );
    }
  };

  const getActualsJSX = (actualsItem, idx) => {
    if (
      [
        ACTUALS_LABELS.UOM,
        ACTUALS_LABELS.TIME_RANGE,
        ACTUALS_LABELS.REFRESH_RATE,
      ].includes(actualsItem.label)
    ) {
      return (
        <Box>
          <CustomRow key={idx}>
            <RowParameter>
              <CustomTypography>{actualsItem.label}</CustomTypography>
            </RowParameter>
            <RowValue>{renderSwitch(actualsItem)}</RowValue>
          </CustomRow>
          {actualsItem.label === ACTUALS_LABELS.REFRESH_RATE &&
            Boolean(refreshRateErrorMessage) && (
              <ErrorRow>
                <Box sx={{ marginLeft: '32%', display: 'flex' }}>
                  <RowValueBox>
                    <FieldError errorMessage={refreshRateErrorMessage} />
                  </RowValueBox>
                </Box>
              </ErrorRow>
            )}
          {(actualsEmptyFields.includes(actualsItem.label) ||
            (actualsItem.label === ACTUALS_LABELS.REFRESH_RATE &&
              (actualsEmptyFields.includes(ACTUALS_LABELS.REFRESH_RATE_VALUE) ||
                actualsEmptyFields.includes(
                  ACTUALS_LABELS.REFRESH_RATE_UNIT
                )))) && (
            <ErrorRow>
              <Box sx={{ marginLeft: '32%', display: 'flex' }}>
                {getEmptyFiledsError(actualsItem.label)}
              </Box>
            </ErrorRow>
          )}
        </Box>
      );
    } else {
      return (
        <Box>
          <CustomRow key={idx}>
            <PublishedParameter>
              <CustomTypography>{actualsItem.label}</CustomTypography>
            </PublishedParameter>
            <PublishedValue>{renderSwitch(actualsItem)}</PublishedValue>
          </CustomRow>
          {actualsItem.label === ACTUALS_LABELS.FREQUENCY_PUBLISHED &&
            Boolean(frequencyPublishedErrorMessage) && (
              <ErrorRow>
                <Box sx={{ marginLeft: '60%' }}>
                  <Box sx={{ marginLeft: '20%' }}>
                    <RowValueBox>
                      <FieldError
                        errorMessage={
                          MESSAGE_STRINGS[
                            'ERROR_MESSAGES.frequencyPublishedValidation'
                          ]
                        }
                      />
                    </RowValueBox>
                  </Box>
                </Box>
              </ErrorRow>
            )}
          {actualsItem.label === ACTUALS_LABELS.TIME_PUBLISHED &&
            Boolean(
              timePublished && timePublished.toString() === 'Invalid Date'
            ) && (
              <ErrorRow>
                <Box sx={{ marginLeft: '60%' }}>
                  <Box sx={{ marginLeft: '20%' }}>
                    <RowValueBox>
                      <FieldError
                        errorMessage={
                          MESSAGE_STRINGS['ERROR_MESSAGES.timeValidation']
                        }
                      />
                    </RowValueBox>
                  </Box>
                </Box>
              </ErrorRow>
            )}
          {actualsEmptyFields.includes(actualsItem.label) && (
            <ErrorRow>
              <Box sx={{ marginLeft: '60%' }}>
                <Box sx={{ marginLeft: '20%' }}>
                  <RowValueBox>
                    <FieldError
                      errorMessage={
                        MESSAGE_STRINGS[
                          'ERROR_MESSAGES.requiredFieldValidation'
                        ]
                      }
                    />
                  </RowValueBox>
                </Box>
              </Box>
            </ErrorRow>
          )}
        </Box>
      );
    }
  };

  return (
    <Accordion
      id={'actuals'}
      expanded={isExpanded}
      onChange={() => {
        onExpandUpdate(OEE_ACCORDIONS.ACTUALS);
      }}
    >
      <Accordion.AccordionSummary
        aria-controls="actuals-content"
        id="actuals-content"
        expandIcon={<DownArrow height={8.5} width={16} />}
        data-testid={TESTIDS.ACTUALS_ACCORDION}
      >
        <Box>
          <AccordionTitle>
            {MESSAGE_STRINGS.ACTUALS_ACCORDION_HEADER}
          </AccordionTitle>
        </Box>
        <AccordionStatus
          type={OEE_ACCORDIONS.ACTUALS}
          status={accordionStatus}
        />
      </Accordion.AccordionSummary>
      <Accordion.AccordionDetails data-testid={TESTIDS.ACTUALS_CONTENT}>
        <Box>
          <ActualsHeaderRow>
            <RowParameter>
              <CustomTypography isHeading>
                {MESSAGE_STRINGS['HEADER.parameter']}
              </CustomTypography>
            </RowParameter>
            <RowValue>
              <CustomTypography isHeading>
                {MESSAGE_STRINGS['HEADER.value']}
              </CustomTypography>
            </RowValue>
          </ActualsHeaderRow>
          {apiResponse.actuals.map((actualsItem, idx) => {
            return getActualsJSX(actualsItem, idx);
          })}
          <FooterBox>
            <GeneralButton
              text="Save"
              disabled={
                Boolean(refreshRateErrorMessage) ||
                Boolean(frequencyPublishedErrorMessage) ||
                (timePublished &&
                  timePublished.toString() === 'Invalid Date') ||
                actualsEmptyFields.length !== 0
              }
              onClick={handleOnSave}
              data-testid={ACTUALS_ID.SAVE_BUTTON}
            >
              <CustomTypography isButtonLabel>Save</CustomTypography>
            </GeneralButton>
          </FooterBox>
        </Box>
      </Accordion.AccordionDetails>
      <Box
        display="flex"
        maxHeight="100%"
        flex={1}
        flexDirection="column"
        data-testid={TESTIDS.BACKDROP}
      >
        <CustomBackdrop open={backdropLoader}>
          <LoadingIndicator />
        </CustomBackdrop>
      </Box>
    </Accordion>
  );
};

Actuals.propTypes = {
  onExpandUpdate: PropTypes.func,
  isExpanded: PropTypes.bool,
  fetchConfigStatuses: PropTypes.func,
};
export default Actuals;
