import React from 'react';
import { Box, Backdrop, CircularProgress, styled } from '@mui/material';
import PropTypes from 'prop-types';

const DangerText = styled(Box)(({ theme }) => ({
  color: theme.palette.background.default,
  textAlign: 'center',
}));

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1,
}));

export default function LoadingIndicator({ size }) {
  return (
    <>
      <StyledBackdrop invisible open />
      <DangerText>
        <CircularProgress color="inherit" size={size} />
      </DangerText>
    </>
  );
}

LoadingIndicator.propTypes = {
  size: PropTypes.number,
};
