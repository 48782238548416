import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ACCORDION_STATUS,
  FILE_CONTENT_TYPES,
  FILE_UPLOAD_STATUSES,
} from '../../constants/OeeConstants';
import { Box } from '@mui/material';
import { convertCase } from '../../utils/helpers';
import UploadBox from '../UploadBox';

const SecondaryUploadPreview = forwardRef((props, ref) => {
  const {
    entityId,
    isValidateError,
    validate,
    resetValidate,
    setIsUploadError,
    updateAccordionStatus,
    getPresignedUploadUrl,
    utility,
    showTable,
    setShowTable,
    setRemoveUploadButton,
    setFileWithMeta,
  } = props;
  const [isFilePreparing, setIsFilePreparing] = useState(false);
  const getUploadUrl = async (file) => {
    const params = {
      utility: utility,
      contentType: FILE_CONTENT_TYPES.XLSX,
      fileName: file,
      entityId: entityId,
    };
    const result = await getPresignedUploadUrl(params);
    return result.data;
  };

  const getUploadParams = async (params) => {
    const { file, meta } = params;
    const uploadUrl = await getUploadUrl(meta.name);
    return {
      body: file,
      url: uploadUrl,
      meta,
      method: 'PUT',
      headers: {
        'Content-Type': FILE_CONTENT_TYPES.XLSX,
      },
    };
  };

  const handleChangeStatus = (fileDetails, status) => {
    if (status === FILE_UPLOAD_STATUSES.DONE) {
      setFileWithMeta(fileDetails);
      validate(fileDetails.meta.name);
    }
    if (
      status === FILE_UPLOAD_STATUSES.PREPARING ||
      status === FILE_UPLOAD_STATUSES.GETTING_UPLOAD_PARAMS ||
      status === FILE_UPLOAD_STATUSES.UPLOADING
    ) {
      setShowTable(false);
      setRemoveUploadButton(true);
      updateAccordionStatus(convertCase(ACCORDION_STATUS.IN_PROGRESS));
      setIsFilePreparing(true);
    }
  };
  const handleReupload = (fileWithMeta) => {
    fileWithMeta.remove();
    ref.current?.dropzone?.current?.firstChild?.lastChild?.click();
    setIsUploadError(false);
    resetValidate();
    setIsFilePreparing(false);
  };
  return (
    //DropZone component must present on DOM to make the upload click to happen
    //Hence Toggling b/w display flag
    <Box sx={{ display: showTable ? 'none' : '' }}>
      <UploadBox
        ref={ref}
        getUploadParams={getUploadParams}
        handleChangeStatus={handleChangeStatus}
        handleReupload={handleReupload}
        isValidateError={isValidateError}
        isFilePreparing={isFilePreparing}
        utility={utility}
      />
    </Box>
  );
});

SecondaryUploadPreview.displayName = SecondaryUploadPreview;

SecondaryUploadPreview.propTypes = {
  entityId: PropTypes.string,
  isValidateError: PropTypes.bool,
  validate: PropTypes.func,
  resetValidate: PropTypes.func,
  updateAccordionStatus: PropTypes.func,
  getPresignedUploadUrl: PropTypes.func,
  utility: PropTypes.string,
  setIsUploadError: PropTypes.func,
  setShowTable: PropTypes.func,
  showTable: PropTypes.bool,
  setRemoveUploadButton: PropTypes.func,
  setFileWithMeta: PropTypes.func,
};

export default SecondaryUploadPreview;
