import { ListItem, Typography, styled } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const ListItemWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showIcon',
})((showIcon) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: showIcon ? '0.3rem' : undefined,
}));

const SelectedMarker = styled('div')(({ theme }) => ({
  height: '2.2rem',
  position: 'absolute',
  width: 0,
  border: `2px solid ${theme.palette.border.saveGreen}`,
  transform: 'translateY(0.1rem)',
  zIndex: 1,
}));

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'showIcon' && prop !== 'isUpdated',
})(({ theme, showIcon, isUpdated }) => ({
  '&.Mui-selected': {
    background: 'transparent',
    fontWeight: 700,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: `${theme.palette.background.gunmetalGrey}`,
    },
  },
  '&:hover': {
    backgroundColor: `${theme.palette.background.gunmetalGrey}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.text.darkGrey,
    opacity: 1,
  },
  '&.listItemShowIconPadding': {
    paddingLeft: showIcon ? 0 : undefined,
  },
  color: isUpdated ? theme.palette.text.darkGrey : undefined,
  opacity: isUpdated ? 1 : undefined,
}));

function PanelListItem({
  isSelected,
  id,
  label = '',
  onClick,
  showIcon = null,
  isUpdated,
}) {
  return (
    <>
      {isSelected ? <SelectedMarker /> : null}
      <ListItemWrapper showIcon={showIcon}>
        {showIcon || null}
        <StyledListItem
          button
          selected={isSelected}
          className={`${Boolean(showIcon) && 'listItemShowIconPadding'}`}
          key={id}
          value={id}
          disableRipple
          onClick={onClick}
          showIcon={showIcon}
          isUpdated={isUpdated}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: isSelected ? 700 : 400 }}
          >
            {label}
          </Typography>
        </StyledListItem>
      </ListItemWrapper>
    </>
  );
}

PanelListItem.propTypes = {
  isSelected: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  showIcon: PropTypes.node,
  isUpdated: PropTypes.bool,
};

export default PanelListItem;
