import { styled } from '@mui/material';
import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: theme.palette.text.lightYellowishGray,
    backgroundColor: theme.palette.background.eerieBlack,
    boxShadow: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.5)',
    border: `1px solid ${theme.customColors.metalGrey}`,
    bordeRadius: '0.25rem',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      backgroundColor: theme.palette.background.eerieBlack,
    },
  },
}));

export default CustomTooltip;
