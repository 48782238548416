import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import LoadingIndicator from '../../../components/LoadingIndicator';
import {
  IRR_HEADER_CONSTANTS,
  MESSAGE_STRINGS,
  QUERY_CONSTANTS,
} from '../../../constants/OeeConstants';
import { useToastContext } from '../../../context/toastContext';
import { getIdealRunRateHierarchy } from '../../../utils/apiHelpers';
import CustomBackdrop from '../../../styledComponents/CustomBackdrop';
import PanelTable from '../../../components/HierarchyTable';
import { Box } from '@mui/material';
import {
  onApiWarningToastHandler,
  onGetHierarchySuccessHandler,
} from '../../../utils/helpers';

const IdealRunRateTable = ({
  isValidationSuccess,
  entityId,
  isFileNewlyUploaded,
}) => {
  const [tableHierarchyData, setTableHierarchyData] = useState(null);
  const [uom, setUom] = useState();

  const { toastDispatch } = useToastContext();

  const {
    isFetching: isIRRValueHierarchyFetching,
    isLoading: isIRRValueHierarchyLoading,
    refetch: refetchIRRHierarchy,
  } = useQuery(
    [QUERY_CONSTANTS.GET_IRR_VALUE_HIERARCHY, entityId],
    async () => {
      return getIdealRunRateHierarchy(entityId);
    },
    {
      onSuccess: (response) => {
        setUom(response.UoM);
        onGetHierarchySuccessHandler(
          setTableHierarchyData,
          response,
          isFileNewlyUploaded,
          toastDispatch
        );
      },

      onError: () => {
        onApiWarningToastHandler(toastDispatch);
      },
      refetchOnWindowFocus: false,
      enabled: Boolean(entityId),
      retry: false,
    }
  );

  useEffect(() => {
    if (isValidationSuccess) {
      refetchIRRHierarchy();
    }
  }, [isValidationSuccess]);

  const backdropLoader =
    isIRRValueHierarchyFetching || isIRRValueHierarchyLoading;

  return (
    <>
      {tableHierarchyData && (
        <PanelTable
          isLoading={false}
          data={tableHierarchyData.data}
          headerKeys={[IRR_HEADER_CONSTANTS.IDEAL_RUN_RATE]}
          nestingLevel={5}
          isIRR={true}
          treePanelHeader={[
            {
              heading: MESSAGE_STRINGS['OEE.tree.header.equipmentHierarchy'],
            },
            {
              heading: `${MESSAGE_STRINGS['OEE.tree.header.IRR_1']}${uom}${MESSAGE_STRINGS['OEE.tree.header.IRR_2']}`,
            },
          ]}
        />
      )}
      <Box display="flex" maxHeight="100%" flex={1} flexDirection="column">
        <CustomBackdrop open={backdropLoader}>
          <LoadingIndicator />
        </CustomBackdrop>
      </Box>
    </>
  );
};

IdealRunRateTable.propTypes = {
  entityId: PropTypes.string,
  isValidationSuccess: PropTypes.bool,
  isFileNewlyUploaded: PropTypes.bool,
};

export default IdealRunRateTable;
