import { useEffect, useMemo } from 'react';
import throttle from 'lodash/throttle';

const noop = () => {
  // This is intentional
};

// https://github.com/mui-org/material-ui/issues/16359#issuecomment-617365879

const useThrottledOnScroll = (callback, delay, target) => {
  const throttledCallback = useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay]
  );

  useEffect(() => {
    if (throttledCallback === noop) {
      return undefined;
    }

    target.addEventListener('scroll', throttledCallback);
    return () => {
      target.removeEventListener('scroll', throttledCallback);
      throttledCallback.cancel();
    };
  }, [target, throttledCallback]);
};

export default useThrottledOnScroll;
