import {
  MESSAGE_STRINGS,
  DROPDOWN_CONSTANTS,
  ACTUALS_LABELS,
  TOAST_REDUCER_CONSTANTS,
} from '../constants/OeeConstants';

const getRefreshRateErrorMessage = (unit, val, errorMessage, valNumber) => {
  let refreshRateIsValid = '';
  switch (unit) {
    case 'Seconds':
      refreshRateIsValid =
        !val.includes('.') && Number.isInteger(valNumber) && valNumber >= 60;
      errorMessage = refreshRateIsValid
        ? ''
        : MESSAGE_STRINGS['ERROR_MESSAGES.secondsValidation'];
      break;
    case 'Minutes':
      refreshRateIsValid =
        !val.includes('.') && Number.isInteger(valNumber) && valNumber >= 1;
      errorMessage = refreshRateIsValid
        ? ''
        : MESSAGE_STRINGS['ERROR_MESSAGES.minutesValidation'];
      break;
    case 'Hours':
      refreshRateIsValid =
        !val.includes('.') && Number.isInteger(valNumber) && valNumber >= 1;
      errorMessage = refreshRateIsValid
        ? ''
        : MESSAGE_STRINGS['ERROR_MESSAGES.hoursValidation'];
      break;
  }
  return errorMessage;
};

export const validateActualsRefreshRate = (val, unit) => {
  let errorMessage = '';
  if (val !== '' && val !== undefined) {
    const valNumber = Number(val);
    errorMessage = getRefreshRateErrorMessage(
      unit,
      val,
      errorMessage,
      valNumber
    );
  }
  return errorMessage;
};

export const validateActualsfrequencyPublished = (frequency) => {
  let errorMessage = '';
  if (frequency !== '' && frequency !== undefined) {
    const n = Number(frequency);
    let frequencyIsValid = '';
    frequencyIsValid =
      !frequency.includes('.') && Number.isInteger(n) && n >= 1;
    errorMessage = frequencyIsValid
      ? ''
      : MESSAGE_STRINGS['ERROR_MESSAGES.frequencyPublishedValidation'];
  }
  return errorMessage;
};

export const validateActualsEmptyFields = (
  timeRange,
  refreshRateValue,
  refreshRateUnit,
  timePublished,
  frequencyPublished
) => {
  let emptyFields;
  if (timeRange === DROPDOWN_CONSTANTS.DEFAULT_VALUE) {
    emptyFields = [ACTUALS_LABELS.TIME_RANGE];
  }
  if (refreshRateValue === '' || refreshRateValue === undefined) {
    emptyFields = emptyFields
      ? [...emptyFields, ACTUALS_LABELS.REFRESH_RATE_VALUE]
      : [ACTUALS_LABELS.REFRESH_RATE_VALUE];
  }
  if (refreshRateUnit === DROPDOWN_CONSTANTS.DEFAULT_VALUE) {
    emptyFields = emptyFields
      ? [...emptyFields, ACTUALS_LABELS.REFRESH_RATE_UNIT]
      : [ACTUALS_LABELS.REFRESH_RATE_UNIT];
  }
  if (timePublished === null) {
    emptyFields = emptyFields
      ? [...emptyFields, ACTUALS_LABELS.TIME_PUBLISHED]
      : [ACTUALS_LABELS.TIME_PUBLISHED];
  }
  if (frequencyPublished === '' || frequencyPublished === undefined) {
    emptyFields = emptyFields
      ? [...emptyFields, ACTUALS_LABELS.FREQUENCY_PUBLISHED]
      : [ACTUALS_LABELS.FREQUENCY_PUBLISHED];
  }
  return { emptyFields: emptyFields };
};

export const convertCase = (status) => {
  return status
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/(?: |\b)(\w)/g, function (key) {
      return key.toUpperCase();
    });
};

export const simulateUrlClick = (url, fileAcceptType) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('type', fileAcceptType);
  link.setAttribute('download', true);
  link.setAttribute('referrerpolicy', 'same-origin');
  link.click();
};

export const getDateTimeStringFromTotalMinutes = (totalMinutes) => {
  const timeRegex = /(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/; // Regex for HH:MM:SS
  const currentDate = new Date().toString();
  const timeHours = Math.floor(totalMinutes / 60);
  const timeMinutes = totalMinutes % 60;
  const timeHoursStr = timeHours < 10 ? `0${timeHours}` : timeHours;
  const timeMinutesStr = timeMinutes < 10 ? `0${timeMinutes}` : timeMinutes;
  const fullTime = `${timeHoursStr}:${timeMinutesStr}:00`;
  return currentDate.replace(timeRegex, fullTime);
};

export const getTotalMinutesFromDateTimeString = (dateTimeString) => {
  const timeRegex = /(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/; // Regex for HH:MM:SS
  const found = dateTimeString.toString().match(timeRegex);
  const time = found[0].split(':'); // ['HH:MM:SS'] => ['HH', 'MM', 'SS']
  const hours = +time[0];
  const minutes = +time[1];
  return hours * 60 + minutes;
};

export const getHHMMFromDateTimeString = (dateTimeString) => {
  const timeRegex = /(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/; // Regex for HH:MM:SS
  const found = dateTimeString.toString().match(timeRegex);
  const time = found[0].split(':'); // ['HH:MM:SS'] => ['HH', 'MM', 'SS']
  const hours = time[0];
  const minutes = time[1];
  return `${hours}:${minutes}`;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const onApiErrorToastHandler = (toastDispatch) => {
  toastDispatch({
    type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
    payload: { message: MESSAGE_STRINGS['Toast.message.ERROR'] },
  });
};

export const onApiWarningToastHandler = (toastDispatch) => {
  toastDispatch({
    type: TOAST_REDUCER_CONSTANTS.SHOW_WARNING_TOAST,
    payload: { message: MESSAGE_STRINGS['Toast.message.WARNING'] },
  });
};

export const onApiSuccessToastHandler = (
  toastDispatch,
  message = MESSAGE_STRINGS['Toast.message.SUCCESS']
) => {
  toastDispatch({
    type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
    payload: {
      message: message,
    },
  });
};

export const onGetHierarchySuccessHandler = (
  setTableHierarchyData,
  response,
  isFileNewlyUploaded,
  toastDispatch
) => {
  setTableHierarchyData(response);
  if (isFileNewlyUploaded) {
    onApiSuccessToastHandler(
      toastDispatch,
      MESSAGE_STRINGS['Toast.hierarchy.upload.SUCCESS']
    );
  }
  return response;
};
