import { styled, Typography } from '@mui/material';

const AccordionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontFamily: 'Open Sans',
  fontWeight: '700',
  lineHeight: '1.192rem',
  color: theme.customColors.white,
}));

export default AccordionTitle;
