import { Box, List, ListItem, styled } from '@mui/material';
import PropTypes from 'prop-types';
import ExclamationMark from '../../assets/img/exclamationMark.svg';
import { TESTIDS } from '../../constants/OeeConstants';

const ErrorBorder = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  background: 'rgba(218, 41, 28, 0.05)',
  borderRadius: '4px',
  minHeight: '3em',
  border: `1px solid ${theme.palette.border.lavaRed}`,
}));

const ErrorList = ({ errorList }) => {
  const isSingleError = errorList.length === 1;
  return (
    <Box
      data-testid={TESTIDS.ERROR_LIST}
      sx={{ width: '100%', padding: '0.625rem' }}
    >
      <ErrorBorder>
        <ExclamationMark
          style={{
            height: '1.5em',
            width: '1.5em',
            margin: '0.625em 1em 1em 1em',
          }}
          alt="exclamationMark"
        />
        <List sx={{ margin: 'auto 0', paddingTop: '0' }}>
          {errorList.map((errMsg, idx) => {
            let errorListItems;
            let isErrorHeading = false;
            if (errMsg.includes(':')) {
              errorListItems = errMsg.split(':');
              isErrorHeading = true;
            } else if (errMsg.includes('!')) {
              errorListItems = errMsg.split('!');
              isErrorHeading = true;
            } else {
              errorListItems = [errMsg];
            }
            return (
              <ListItem
                data-testid={TESTIDS.ERROR_LIST_ITEM}
                sx={{
                  paddingLeft: '0.2rem',
                  fontSize: '0.875rem ',
                  fontWeight: '400 ',
                  fontFamily: 'Open Sans ',
                  lineHeight: '1.192rem ',
                  color: 'white',
                  paddingRight: '0',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  marginTop: '0.625rem',
                  height: '1.188rem',
                }}
                key={idx}
              >
                <p>
                  {!isSingleError && <b>{`${idx + 1}. `} </b>}
                  {isErrorHeading && (
                    <b>
                      {errorListItems[0] === 'Invalid Input'
                        ? `${errorListItems[0]}:`
                        : `${errorListItems[0]}!`}
                    </b>
                  )}
                  {isErrorHeading && <>{errorListItems[1]}</>}
                  {!isErrorHeading && <>{errorListItems[0]}</>}
                </p>
              </ListItem>
            );
          })}
        </List>
      </ErrorBorder>
    </Box>
  );
};
export default ErrorList;

ErrorList.propTypes = {
  errorList: PropTypes.array,
};
