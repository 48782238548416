import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { TESTIDS } from '../../constants/OeeConstants';

const StyledAccordionStatusBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.background.gunmetalGrey,
  justifyContent: 'center',
  borderRadius: '0.25rem',
  minWidth: '5.8rem',
  marginLeft: 'auto',
}));

const AccordionStatus = ({ status, type }) => {
  return (
    <StyledAccordionStatusBox>
      <Typography
        data-testid={`${TESTIDS.ACCORDION_STATUS}-${type}`}
        sx={{
          fontWeight: '400',
          fontSize: '0.875rem',
          lineHeight: '1.188rem',
          fontStyle: 'normal',
        }}
      >
        {status}
      </Typography>
    </StyledAccordionStatusBox>
  );
};
AccordionStatus.propTypes = {
  status: PropTypes.string,
  type: PropTypes.string,
};
export default AccordionStatus;
