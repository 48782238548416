import { ButtonBase, FormControl, Grid, Input, styled } from '@mui/material';
import React, { useRef } from 'react';
import GreenUploadIcon from '../../assets/img/greenUploadIcon.svg';
import { TESTIDS } from '../../constants/OeeConstants';

const UploadInput = (inputComponentProps) => {
  const { accept, onFiles, getFilesFromEvent } = inputComponentProps;
  const hiddenFileInput = useRef(null);

  const handleClickUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const chosenFile = getFilesFromEvent(event);
    onFiles(chosenFile);
  };
  return (
    <FormControl htmlFor="uploadButton" style={{ width: '100%' }}>
      <Input
        inputProps={{
          type: 'file',
          accept,
          ref: hiddenFileInput,
        }}
        id="uploadButton"
        name="uploadButton"
        disableUnderline
        onChange={handleChange}
        sx={{ display: 'none' }}
      />
      <ButtonBase
        onClick={handleClickUpload}
        data-testid={TESTIDS.INITIAL_UPLOAD_BUTTON}
        disableRipple
        sx={{
          padding: '0em 6em',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
      >
        <Grid item>
          <GreenUploadIcon
            height="3.125rem"
            width="3.125rem"
            alt="upload-icon"
          />
        </Grid>
      </ButtonBase>
    </FormControl>
  );
};

export default UploadInput;
