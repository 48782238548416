import {
  Box,
  ButtonBase,
  LinearProgress,
  Typography,
  styled,
} from '@mui/material';
import React from 'react';
import XLSXIcon from '../../assets/img/xlsxIcon.svg';
import GreenUploadIcon from '../../assets/img/greenUploadIcon.svg';
import ExclamationMark from '../../assets/img/exclamationMark.svg';
import { MESSAGE_STRINGS, TESTIDS } from '../../constants/OeeConstants';
import { formatBytes } from '../../utils/helpers';

const ProgressContainer = styled(Box)(({ theme }) => ({
  width: '25.625rem',
  backgroundColor: theme.palette.background.elevationGrey,
  padding: '0.75rem',
  boxShadow: '0 0.25em 0.25em rgba(0, 0, 0, 0.25)',
  borderRadius: '0.25rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '0.438rem',
  borderRadius: '0.25rem',
  backgroundColor: theme.customColors.progressBarEmpty,
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.customColors.progressBarBlue,
  },
}));

const SmallUploadIcon = styled('div')(() => ({
  height: '3.125rem',
  width: '3.125rem',
}));

const FileSizeTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '1.362rem',
  color: theme.palette.text.blockGrey,
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: '1rem',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '1.362rem',
  marginTop: '0.625rem',
}));

const UploadPreview = ({
  previewComponentProps,
  handleReupload,
  isValidateError,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { meta: { name, size, percent } = {}, fileWithMeta } =
    previewComponentProps;
  return (
    <div>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <ProgressContainer>
            <div style={{ flexGrow: '0.2' }}>
              <XLSXIcon width="2.708rem" height="3.25rem" alt="xlsx-icon" />
            </div>
            <Box
              sx={{
                flexGrow: '1',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginLeft: '2rem',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <div>{name}</div>
              </Box>
              <FileSizeTypography>{formatBytes(size)}</FileSizeTypography>
              <StyledLinearProgress
                variant="determinate"
                value={isValidateError ? 70 : Math.round(percent)}
              />
            </Box>
            {isValidateError && (
              <Box
                sx={{ flexGrow: '0.2', marginLeft: '2.125rem' }}
                data-testid={TESTIDS.PREVIEW_ERROR}
              >
                <ExclamationMark height={32} width={32} alt="error-icon" />
              </Box>
            )}
          </ProgressContainer>
        </div>
        {isValidateError && (
          <Box
            sx={{
              flexGrow: '1',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '1.5rem',
            }}
          >
            <ButtonBase
              data-testid={TESTIDS.ERROR_UPLOAD_BTN}
              onClick={() => handleReupload(fileWithMeta)}
              disableRipple
            >
              <SmallUploadIcon>
                <GreenUploadIcon
                  alt="reupload-icon"
                  height="3.125rem"
                  width="3.125rem"
                />
              </SmallUploadIcon>
            </ButtonBase>
            <StyledTypography>
              {MESSAGE_STRINGS['Targets.reupload.preview.text']}
            </StyledTypography>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default UploadPreview;
