import { oee, factoryHealth } from '@smf/ui-util-app';

export async function getActuals(entityId, utility) {
  return oee.getActuals(entityId, utility);
}

export async function updateActuals(body) {
  return oee.updateActuals(body);
}

export async function getPresignedExportUrl(entityId, utility) {
  return oee.getPresignedExportUrl(entityId, utility);
}

export async function validateUpload(body) {
  return oee.validateUpload(body);
}

export async function getTargetsHierarchy(entityId) {
  return oee.getTargetsHierarchy(entityId);
}

export async function getIdealRunRateHierarchy(entityId) {
  return oee.getIdealRunRateHierarchy(entityId);
}

export async function getTargetsAndIdealRunRateStatus(entityId, utility) {
  return oee.getTargetsAndIdealRunRateStatus(entityId, utility);
}

export async function getPresignedUploadUrl(params) {
  return oee.getPresignedUploadUrl(params);
}

export async function getIdealPresignedExportUrl(entityId) {
  return oee.getIdealPresignedExportUrl(entityId);
}

export async function idealValidateUpload(body) {
  return oee.idealValidateUpload(body);
}

export async function getIdealPresignedUploadUrl(params) {
  return oee.getIdealPresignedUploadUrl(params);
}
export async function getDeviationThresholds(entityId) {
  return oee.getDeviationThresholds(entityId);
}

export async function updateDeviationThresholds(body) {
  return oee.updateDeviationThresholds(body);
}

export async function getFactoryConfigStatuses(entityId) {
  return factoryHealth.getFactoryConfigStatuses(entityId);
}
