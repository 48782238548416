export const OEE_ACCORDIONS = {
  ACTUALS: 'ACTUALS',
  TARGETS: 'TARGETS',
  IDEAL_RUN_RATE: 'IDEAL_RUN_RATE',
  DEVIATION_THRESHOLD: 'DEVIATION_THRESHOLD',
};

export const ACCORDION_STATUS = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
};

export const MESSAGE_STRINGS = {
  'HEADER.parameter': 'Parameter',
  'HEADER.value': 'Value',
  'HEADER.Metric': 'OEE Metric',
  'HEADER.Deviation_from_target': 'Deviation from Target',

  //Actuals
  ACTUALS_ACCORDION_HEADER: 'Actuals',
  'TIME_RANGE.shift': 'Shift',
  'TIME_RANGE.production_day': 'Production Day',
  'REFRESH_RATE.seconds': 'Seconds',
  'REFRESH_RATE.minutes': 'Minutes',
  'REFRESH_RATE.hours': 'Hours',
  'TIME_PUBLISHED.label': 'Time',
  'FREQUENCY_PUBLISHED.label': 'Frequency',
  'FREQUENCY_PUBLISHED.unit': 'days',

  //Targets
  TARGETS_ACCORDION_HEADER: 'Targets',
  'TARGETS.uploadXlsxPerProdDay': 'Upload Targets XLSX',
  'TARGETS.uploadXlsx': 'Upload XLSX',
  'TARGETS.exportXlsx': 'Export Data',
  'Targets.reupload.preview.text': 'Re-Upload',
  'TARGETS_UPLOAD.message':
    'If you have already uploaded a XLSX file, clicking this icon again will allow you to upload a new XLSX that will replace the previous XLSX file. All data from the first XLSX file will be removed.',
  'GeneralDropdown.default.placeholder': 'Select',
  //Ideal Run Rate
  IDEAL_RUN_RATE_ACCORDION_HEADER: 'Ideal Run Rate',
  'IRR.uploadXlsxPerProdDay': 'Upload Ideal Run Rate XLSX',
  'OEE.tree.header.equipmentHierarchy': 'Equipment Hierarchy',
  'OEE.tree.header.OEE': 'OEE (%)',
  'OEE.tree.header.Availability': 'Availability (%)',
  'OEE.tree.header.Performance': 'Performance (%)',
  'OEE.tree.header.Quality': 'Quality (%)',

  //Deviation Threshold
  DEVIATION_THRESHOLD_ACCORDION_HEADER: 'Deviation Thresholds',
  'DEVIATION.info':
    'Lower Bound and Upper Bound thresholds for Red, Amber, and Green must be set to indicate significance of the Actual values deviation from the configured Target values.',

  'OEE.tree.header.IRR_1': 'Ideal Run Rate (',
  'OEE.tree.header.IRR_2': ' per Second)',

  //ERROR MESSAGES
  'ERROR_MESSAGES.secondsValidation': 'Value must be 60 seconds or more',
  'ERROR_MESSAGES.minutesValidation': 'Value must be 1 minute or more',
  'ERROR_MESSAGES.hoursValidation': 'Value must be 1 hour or more',
  'ERROR_MESSAGES.frequencyPublishedValidation':
    'Frequency cannot be less than 1 Day.',
  'ERROR_MESSAGES.requiredFieldValidation': 'Required Field',
  'ERROR_MESSAGES.timeValidation': 'Value must be between 00:00 and 23:59',

  //TOAST MESSAGES
  'Toast.message.SUCCESS': 'Your changes have been successfully saved!',
  'Toast.hierarchy.upload.SUCCESS': 'File has been successfully uploaded!',
  'Toast.message.ERROR': 'Something went wrong. Please try again later!',
  'Toast.message.WARNING': 'Oops, something went wrong!',
  'Toast.message.INFO': 'Please wait',

  //MODAL
  'WarningModal.okay': 'Okay',
  'WarningModal.title': 'Warnings',

  //AIP WARNING MESSAGES
  'AIP.warning.button': 'View 2',
  'AIP.warning.IRR.prompt':
    ' warnings. There have been updates on the General Configuration screen. Please re-upload Ideal Run Rates.',
  'AIP.warning.IRR.message':
    'There has been an update on the Equipment Intelligence table on the General Configuration screen. Please re-upload Ideal Run Rates.',
  'AIP.warning.Targets.message':
    'There has been an update on the Equipment Intelligence table on the General Configuration screen. Please re-upload the Targets.',
  'UoM.warning.IRR.message':
    'There has been an update to UoM on the Plant Financial Calculations in General Configuration. Please re-upload Ideal Run Rates.',
};

export const DROPDOWN_CONSTANTS = { DEFAULT_VALUE: 'SELECT' };

export const ACTUALS_ID = {
  TIME_RANGE_SELECTION: 'dropdown-timerange',
  REFRESH_RATE_TEXTFIELD: 'refreshrate-textfield',
  TIME_PUBLISHED_TEXTFIELD: 'time-published-textfield',
  FREQUENCY_PUBLISHED_TEXTFIELD: 'frequency-published-textfield',
  REFRESH_RATE_SELECTION: 'dropdown-refreshrate',
  TIME_RANGE_ITEMS: 'timerange-items',
  REFRESH_RATE_ITEMS: 'refreshrate-items',
  SAVE_BUTTON: 'save-button',
};

export const OEE_ID = {
  OEE_DEVIATION_THRESHOLD: 'Deviation Threshold',
};

export const ACTUALS_CONSTANTS = {
  REFRESH_RATE_UNIT_SECONDS: 'Seconds',
  REFRESH_RATE_UNIT_MINUTES: 'Minutes',
  REFRESH_RATE_UNIT_HOURS: 'Hours',
  TIME_RANGE_SHIFT: 'Shift',
  TIME_RANG_PRODUCTION_DAY: 'Production Day',
  INVALID_DATE: 'Invalid Date',
};

export const ACTUALS_LABELS = {
  UOM: 'UoM',
  TIME_RANGE: 'Time Range',
  REFRESH_RATE: 'Refresh Rate',
  REFRESH_RATE_UNIT: 'Refresh Rate Unit',
  REFRESH_RATE_VALUE: 'Refresh Rate Value',
  TIME_PUBLISHED: 'Time the Production Schedule is Published',
  FREQUENCY_PUBLISHED: 'Frequency the Production Schedule is Published',
};

export const DEFAULT_API_RESPONSE = {
  actuals: [
    {
      label: ACTUALS_LABELS.UOM,
      value: 'Units',
    },
    {
      label: ACTUALS_LABELS.TIME_RANGE,
      value: DROPDOWN_CONSTANTS.DEFAULT_VALUE,
    },
    {
      label: ACTUALS_LABELS.REFRESH_RATE,
      value: undefined,
      unit: DROPDOWN_CONSTANTS.DEFAULT_VALUE,
    },
    {
      label: ACTUALS_LABELS.TIME_PUBLISHED,
      unit: 'Minutes',
      value: undefined,
    },
    {
      label: ACTUALS_LABELS.FREQUENCY_PUBLISHED,
      unit: 'Days',
      value: null,
    },
  ],
  entityId: '',
  status: 'NOT_STARTED',
};

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};
export const BUTTON_SIZE = {
  STANDARD: 'standard',
  SMALL: 'small',
};

export const FILE_CONTENT_TYPES = {
  CSV: 'text/csv',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const QUERY_CONSTANTS = {
  OEE_UTILITY: 'OEE',
  GET_ACTUALS: 'getActuals',
  UPDATE_ACTUALS: 'updateActuals',
  EXPORT_XLSX: 'downloadXlsx',
  UPLOAD_XLSX: 'uploadXlsx',
  VALIDATE_XLSX: 'validateXlsx',
  IDEAL_RR_UTILITY: 'IdealRunRate',
  EXPORT_IDEAL_XLSX: 'downloadIdealXlsx',
  UPLOAD_IDEAL_XLSX: 'uploadIdealXLSX',
  VALIDATE_IDEAL_XLSX: 'validateIdealXlsx',
  GET_TARGETS_VALUE_HIERARCHY: 'getTargertsValueHierarchy',
  GET_IRR_VALUE_HIERARCHY: 'getIdealRunRateValueHierarchy',
  OEE_TARGETS_AND_IDEAL_RUN_RATE_STATUS: 'getTargetsAndIdealRunRateStatus',
  GET_DEVIATION_THRESHOLDS: 'getDeviationThresholds',
  UPDATE_DEVIATION_THRESHOLDS: 'updateDeviationThresholds',
  GET_CONFIG_STATUSES: 'getFactoryConfigStatuses',
};

export const OEE_TITLE = 'Overall Equipment Effectiveness';

export const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const TOAST_REDUCER_CONSTANTS = {
  SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
  SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
};

export const FILE_UPLOAD_STATUSES = {
  DONE: 'done',
  UPLOADING: 'uploading',
  ABORTED: 'aborted',
  RESTARTED: 'restarted',
  REMOVED: 'removed',
  STARTED: 'started',
  READY: 'ready',
  PREPARING: 'preparing',
  GETTING_UPLOAD_PARAMS: 'getting_upload_params',
  HEADERS_RECEIVED: 'headers_received',
  REJECTED_FILE: 'rejected_file_type',
  UPLOAD_PARAMS_ERROR: 'error_upload_params',
};

export const TESTIDS = {
  EXPORT_BUTTON: 'export_button',
  INITIAL_UPLOAD_BUTTON: 'initial_upload_button',
  HIERARCHY_TABLE_COMPONENT: 'hierarchy_table_component',
  HIERARCHY_TABLE_CONTAINER: 'hierarchy_table_container',
  ERROR_LIST: 'error-list',
  ERROR_LIST_ITEM: 'error-list-item',
  PREVIEW_ERROR: 'preview-error',
  ERROR_UPLOAD_BTN: 'error-upload-button',
  BACKDROP: 'backdrop',
  SECONDARY_HEADER: 'secondary-header',
  SECONDARY_UPLOAD_BTN: 'secondary-upload-button',
  TOOlTIP: 'tooltip',
  TARGETS_TABLE: 'targets-table',
  SLIDER: 'slider',
  MIN_VAL: 'slider-min',
  MAX_VAL: 'slider-max',
  DEVIATION_SAVE: 'deviation-save',
  OEE_MAIN: 'oee-main',
  ACCORDION_STATUS: 'accordion-status',
  TARGETS_AIP_WARNING: 'targets-aip-warning',
  IRR_AIP_WARNING: 'irr-aip-warning',
  IRR_UOM_WARNING: 'irr-uom-warning',
  IRR_MULTIPLE_WARNING: 'irr-multiple-warning',
  WARNING_MODAL: 'warning-modal',
  WARNING_BUTTON: 'warning-btn',
  WARNING_MESSAGE: 'warning-message',
  WARNING_MODAL_BUTTON: 'warning-modal-btn',
  IRR_ACCORDION: 'irr-accordion',
  IRR_CONTENT: 'irr-content',
  TARGETS_ACCORDION: 'targets-accordion',
  TARGETS_CONTENT: 'targets-content',
  DEVIATION_ACCORDION: 'deviation-accordion',
  DEVIATION_CONTENT: 'deviation-content',
  ACTUALS_ACCORDION: 'actuals-accordion',
  ACTUALS_CONTENT: 'actuals-content',
};

export const ENTITY_TYPES = {
  PLANT: 'Plant',
  AREA: 'Area',
  ZONE: 'Zone',
  CELL: 'Cell',
};

export const TARGETS_HEADER_CONSTANTS = {
  AVAILABILITY_TARGETS: 'availabilityTarget',
  QUALITY_TARGETS: 'qualityTarget',
  PERFORMANCE_TARGETS: 'performanceTarget',
  OEE_TARGETS: 'oeeTarget',
};

export const DEVIATION_THRESHOLD_CONSTANTS = {
  SLIDER_LABEL: 'Target',
  MIN_LIMIT: '-100%',
  MAX_LIMIT: '0%',
  OEE_METRIC: ['OEE', 'Availability', 'Performance', 'Quality'],
  COLORS: {
    RED: 'RED',
    AMBER: 'AMBER',
    GREEN: 'GREEN',
  },
};

export const IRR_HEADER_CONSTANTS = {
  IDEAL_RUN_RATE: 'idealRunRate',
};
